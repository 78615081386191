import ITextProps from '../ITextProps';

import { FontWeight } from 'shared/enums';

import c from './textS.module.scss';

const TextS = ({
  children,
  hexColor,
  className,
  color,
  fontWeight,
  text,
  textAlign,
  noWrap,
  title,
  onClick,
}: ITextProps) => {
  return (
    <div
      onClick={onClick}
      title={title || ''}
      className={`${className || ''} ${c.textS}`}
      style={{
        textAlign: textAlign || 'initial',
        fontWeight: fontWeight || FontWeight.Light,
        color: hexColor ? hexColor : color ? `var(${color})` : '',
        whiteSpace: noWrap ? 'nowrap' : undefined,
      }}>
      {text || children}
    </div>
  );
};

export default TextS;
