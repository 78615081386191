import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { FormRow, IconBtn, Modal, PrimaryButton, Spinner, TextInput } from 'components';

import { authUserStore } from 'stores';

import { TextL, TextM } from 'components/TextWrappers';

import { RecoveryPasswordForm, RegistrationForm } from './components';
import { appRoutes } from 'shared/constants/appRoutes';

import c from './authPage.module.scss';

const AuthPage = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!authUserStore.authUser.authSuccess) return;

    history.push(appRoutes.main);
  }, [authUserStore.authUser.authSuccess]);

  useEffect(() => {
    return () => {
      authUserStore.authUser.deInit();
    };
  }, []);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      authUserStore.signIn();
    }
  };

  return (
    <div className={c.authPageWrapper} onKeyDown={onKeyDown} tabIndex={0}>
      <div
        className={
          authUserStore.isShowRecoverPasswordForm || authUserStore.isShowRegistrationForm
            ? c.companyLogoOnTop
            : c.companyLogo
        }></div>

      <div className={c.formWrapper}>
        <Spinner isLoading={authUserStore.isPending} />
        <TextL className={c.formTitle} text={'Вход'} />

        <FormRow
          label={'Email'}
          inputWidthInPercents={80}
          validationMessage={authUserStore.authUser.validation.checkRequirements[0].currentNotice}
          input={
            <TextInput
              validStatus={authUserStore.authUser.validation.checkRequirements[0].validStatus}
              value={authUserStore.authUser.login}
              onChange={authUserStore.authUser.onChangeLogin}
            />
          }
        />

        <FormRow
          additionalClass={c.authFormRow}
          label={'Пароль'}
          inputWidthInPercents={80}
          validationMessage={authUserStore.authUser.validation.checkRequirements[1].currentNotice}
          input={
            <TextInput
              validStatus={authUserStore.authUser.validation.checkRequirements[1].validStatus}
              password={!isShowPassword}
              value={authUserStore.authUser.password}
              onChange={authUserStore.authUser.onChangePassword}
              endAdornment={
                <IconBtn
                  className={c.showPswBtn}
                  icon={isShowPassword ? <Visibility /> : <VisibilityOff />}
                  onMouseDown={() => setIsShowPassword(true)}
                  onMouseUp={() => setIsShowPassword(false)}
                  onMouseLeave={() => setIsShowPassword(false)}
                />
              }
            />
          }
        />

        <div className={c.additionalActionRow}>
          <TextM text={'Зарегистрироваться'} onClick={authUserStore.openRegistrationForm} />
          <TextM text={'Восстановить пароль'} onClick={authUserStore.openRecoveryPasswordForm} />
        </div>

        <div className={c.actionRow}>
          <PrimaryButton onClick={authUserStore.signIn} caption={'Войти'} />
        </div>
      </div>

      <Modal
        onClose={authUserStore.closeRegistrationForm}
        onConfirm={authUserStore.submitRegistration}
        confirmCaption={'Зарегистрироваться'}
        cancelCaption={'Отмена'}
        isOpened={authUserStore.isShowRegistrationForm}>
        <RegistrationForm />
      </Modal>

      <Modal
        onClose={authUserStore.closeRecoveryPasswordForm}
        onConfirm={authUserStore.submitRecoveryPassword}
        confirmCaption={'Восстановить доступ'}
        cancelCaption={'Отмена'}
        isOpened={authUserStore.isShowRecoverPasswordForm}>
        <RecoveryPasswordForm />
      </Modal>
    </div>
  );
};

export default observer(AuthPage);
