import BaseApiService from 'apiServices/BaseApiService';

import { ITheatreInfoGetDto, ITheatreInfoPutDto } from 'shared/interfaces/api/theatreInfo/ITheatreInfo';

class TheatreApiService extends BaseApiService {
  constructor() {
    super('api/Info');
  }

  public getTheatreInfo(): Promise<ITheatreInfoGetDto> {
    return this.GET(`TheaterInfo`);
  }

  public putTheatreInfo(info: ITheatreInfoPutDto): Promise<any> {
    return this.PUT('/TheaterInfo', info);
  }
}

export default new TheatreApiService();
