import React, { MouseEventHandler } from 'react';

import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';

import c from './iconBtn.module.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  icon: JSX.Element | null;
  hint?: string;
  mediumSize?: boolean;
  onClick?(e: React.MouseEvent): void;
  onMouseDown?: MouseEventHandler;
  onMouseUp?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  hintPlacement?: TooltipPlacement;
}

export enum TooltipPlacement {
  RightEnd = 'right-end',
  Bottom = 'bottom',
  Top = 'top',
  LeftStart = 'left-start',
}

const IconBtn = (props: IProps) => {
  const { onClick, onMouseDown, onMouseUp, onMouseLeave, disabled, hint, className, mediumSize, hintPlacement } = props;

  return (
    <Tooltip title={hint || ''} placement={hintPlacement || 'right-end'}>
      <IconButton
        size={mediumSize ? 'medium' : 'small'}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
        className={disabled ? `${c.iconBtn} ${c.disabled} ${className || ''}` : c.iconBtn || className}>
        {props.icon}
      </IconButton>
    </Tooltip>
  );
};

export default IconBtn;
