import { Utils } from 'services/utils';
import LocalStorageService from 'services/LocalStorageService';

import { SortControl } from '../serve';

import { SortDirection } from '../serve/SortControl';

import { appStore } from 'stores';

import { IUsersStoreDI } from '../../interfaces/app/di';
import { TabNames } from '../../enums';
import UsersAccountsFiltersControl from './UsersAccountsFiltersControl';
import { IUsersAccountParamsPostDto } from '../../interfaces/api/users';

export default class UsersRequestParamsControl {
  constructor(
    private usersStore: IUsersStoreDI,
    private virtualPageLength: number,
    private nextPageRecordStartFrom: number
  ) {}

  public filtersControl = new UsersAccountsFiltersControl(this.usersStore);

  public sortControl = new SortControl({ columnName: 'Email', direction: SortDirection.Desc }, this.usersStore);

  public initFromURL(tabId: TabNames) {
    const requestParams: any = this.getRequestParams(true);

    try {
      for (const requestParamsKey in requestParams) {
        const trueCased = Utils.getRequestParamCase(requestParamsKey);

        const paramValueFromURL = Utils.getUrlParam(trueCased);
        if (paramValueFromURL?.toString()) {
          requestParams[requestParamsKey] = paramValueFromURL;
        }
      }

      this.initParams(tabId, requestParams);
    } catch (e) {
      console.log(e);
    }
  }

  private initParams(tabId: TabNames, requestParams: IUsersAccountParamsPostDto) {
    this.filtersControl.init(requestParams);

    if (!this.filtersControl.hasAnyAppliedFilters) {
      const paramsFromLocalStorage = LocalStorageService.getDataFromLocalStorageByKey<IUsersAccountParamsPostDto>(
        `filterParamsFor_${tabId}`
      );

      if (paramsFromLocalStorage) {
        this.filtersControl.init(paramsFromLocalStorage);
      }
    }

    this.sortControl.init(requestParams.sortField, requestParams.sortByDesc.toString() === 'true');
  }

  public setStateToURL(requestParams: any) {
    const excludeList = ['from', 'count', 'sortbydesc', 'sortfield'];

    try {
      for (const requestParamsKey in requestParams) {
        if (excludeList.includes(requestParamsKey.toLowerCase())) continue;

        const param = requestParams[requestParamsKey]?.toString();
        const trueCased = Utils.getRequestParamCase(requestParamsKey);

        if (param) {
          Utils.insertUrlParam(trueCased, param);
        }

        if (!param) {
          Utils.removeUrlParam(trueCased);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  public getRequestParams(preventPutToURL?: boolean): IUsersAccountParamsPostDto {
    const requestParams: IUsersAccountParamsPostDto = {
      from: this.usersStore.users.length,
      count: this.virtualPageLength,
      sortByDesc: this.sortControl.direction === SortDirection.Desc,
      sortField: this.sortControl.columnName,
      email: this.filtersControl.eMailPattern,
      firstName: this.filtersControl.firstNamePattern,
      secondName: this.filtersControl.secondNamePattern,
      lastName: this.filtersControl.lastNamePattern,
      registrationDateFrom: this.filtersControl.registrationDateFrom,
      registrationDateTo: this.filtersControl.registrationDateTo,
      isConfirmed: this.filtersControl.isConfirmed,
      isDeleted: this.filtersControl.isDeleted,
      type: this.filtersControl.userType,
    };

    if (!preventPutToURL) {
      this.setStateToURL(requestParams);
    }

    return requestParams;
  }

  public deInit(tabId: TabNames, preventRequest?: boolean) {
    const allParams = this.getRequestParams(true);
    LocalStorageService.putDataToLocalStorage(`filterParamsFor_${tabId}`, allParams);

    this.filtersControl.deInit(preventRequest);
    this.sortControl.deInit();

    if (tabId === appStore.selectedTabId) {
      return;
    }

    for (const allParamsKey in allParams) {
      Utils.removeUrlParam(Utils.getRequestParamCase(allParamsKey));
    }
  }
}
