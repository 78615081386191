import React from 'react';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { TextM, TextS } from 'components/TextWrappers';

import { ISelectItem } from 'shared/interfaces/app';

import c from './select.module.scss';

interface IProps {
  error?: boolean;
  disabled?: boolean;
  items: ISelectItem[];
  value: string | null;
  autoFocus?: boolean;
  withEmpty?: boolean;
  emptyLabel?: string;
  onChange(selected: string): void;
}

const SelectWrapper = (props: IProps) => {
  const { error, disabled, items, onChange, value, autoFocus, withEmpty, emptyLabel } = props;

  const onChangeValue = (event: SelectChangeEvent<string | null>) => {
    onChange(event.target.value as string);
  };

  const renderSelected = (selectedId: string) => {
    return <>{items.find((item) => item.id === selectedId)?.name || emptyLabel || ''}</>;
  };

  return (
    <Select
      autoFocus={autoFocus}
      className={c.selectWrapper}
      error={error}
      disabled={disabled}
      variant="outlined"
      labelId="select-label"
      value={value || ''}
      renderValue={renderSelected}
      onChange={onChangeValue}>
      {withEmpty && (
        <MenuItem className={c.menuItem} value={''}>
          <TextM text={emptyLabel || 'Сбросить значение'} fontWeight={400} />
        </MenuItem>
      )}

      {items.map((item) => {
        return (
          <MenuItem className={c.menuItem} key={item.id} value={item.id}>
            <TextS text={item.name} />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default SelectWrapper;
