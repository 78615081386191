import { makeAutoObservable } from 'mobx';

import { IPerformanceStoreDI } from '../../interfaces/app/di';

import { IPerformancePostParamsDto } from '../../interfaces/api/performacnes';
import { SelectedGroup } from '../users/UsersAccountsFiltersControl';

export class PerformanceFilterControl {
  constructor(private performancesStore: IPerformanceStoreDI) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public sortField = '';

  public creatorEmail = '';

  public name = '';

  public description = '';

  public inspiredBy = '';

  public author = '';

  public durationFrom: number | null = null;

  public durationTo: number | null = null;

  public distanceFrom: number | null = null;

  public distanceTo: number | null = null;

  public minAgeFrom: number | null = null;

  public minAgeTo: number | null = null;

  public backgroundColor = '';

  public textColor = '';

  public isComingSoon: boolean | null = null;

  public isArchival: boolean | null = null;

  public isDeleted: boolean | null = null;

  public deletedState: SelectedGroup = SelectedGroup.All;

  public idEvent: number | null = null;

  public init(initParams: IPerformancePostParamsDto) {
    this.creatorEmail = initParams.creatorEmail;
    this.name = initParams.name;
    this.description = initParams.description;
    this.inspiredBy = initParams.inspiredBy;
    this.author = initParams.author;
    this.durationFrom = initParams.durationFrom;
    this.durationTo = initParams.durationTo;
    this.distanceFrom = initParams.distanceFrom;
    this.distanceTo = initParams.distanceTo;
    this.minAgeFrom = initParams.minAgeFrom;
    this.minAgeTo = initParams.minAgeTo;
    this.backgroundColor = initParams.backgroundColor;
    this.textColor = initParams.textColor;
    this.isComingSoon = initParams.isComingSoon === null ? null : initParams.isComingSoon?.toString() === 'true';
    this.isDeleted = initParams.isDeleted === null ? null : initParams.isDeleted?.toString() === 'true';
    this.isArchival = initParams.isArchival === null ? null : initParams.isDeleted?.toString() === 'true';
    this.idEvent = null;

    this.deletedState =
      initParams.isDeleted === null
        ? SelectedGroup.All
        : initParams.isDeleted?.toString() === 'true'
        ? SelectedGroup.SelectedOnly
        : SelectedGroup.UnselectedOnly;
  }

  public get hasAnyAppliedFilters() {
    return this.deletedState !== SelectedGroup.All;
  }

  public onSelectDeleted(selected: SelectedGroup) {
    this.deletedState = selected;

    this.isDeleted =
      selected === SelectedGroup.SelectedOnly ? true : selected === SelectedGroup.UnselectedOnly ? false : null;

    this.performancesStore.setStartRecordNumber(0);
  }

  public onChangeName(value: string) {
    this.name = value;
  }

  public dropDatesFilter() {
    this.performancesStore.setStartRecordNumber(0);
  }

  public deInit(preventRequest?: boolean) {
    this.performancesStore.setStartRecordNumber(0, preventRequest);
    this.isDeleted = null;
    this.deletedState = SelectedGroup.All;
  }
}
