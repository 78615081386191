import BaseApiService from 'apiServices/BaseApiService';

import { ITicketResponseDto, ITicketsParamsPostDto } from 'shared/interfaces/api/tickets';
import dayjs from 'dayjs';
import { Utils } from '../services/utils';

class TicketsApiService extends BaseApiService {
  constructor() {
    super('api/Ticket');
  }

  public getTickets(params: ITicketsParamsPostDto): Promise<ITicketResponseDto> {
    const utcFrom = params.activationTimeFrom ? dayjs(params.activationTimeFrom).toISOString() : '';
    const utcTo = params.activationTimeTo ? Utils.getEndOfDay(params.activationTimeTo) : '';

    const paramsWithUTSTime: any = {
      ...params,
      registrationDateFrom: utcFrom,
      registrationDateTo: utcTo,
    };

    return this.POST(`GetByFilter`, paramsWithUTSTime);
  }

  public refund(ticketId: string): Promise<boolean> {
    return this.PUT(`/Refund/?id=${ticketId}`);
  }
}

export default new TicketsApiService();
