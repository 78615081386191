import { makeAutoObservable } from 'mobx';

import { IPerformanceRowDto } from '../../interfaces/api/performacnes';
import dayjs from 'dayjs';

export class PerformanceRowModel {
  constructor(dto?: IPerformanceRowDto) {
    if (!dto) return;

    makeAutoObservable(this, undefined, { autoBind: true });
    this.id = dto.id;

    if (dto.logo) {
      this.logo = dto.logo.name;
      this.logoId = dto.logo.id;
    }

    this.creatorName = dto.creator.lastName;
    this.name = dto.name;
    this.descriptionSource = dto.description;
    this.inspiredBy = dto.inspiredBy;
    this.author = dto.author;
    this.durationSource = dto.duration;
    this.distanceSource = dto.distance;
    this.minAgeSource = dto.minAge;
    this.backgroundColor = dto.backgroundColor;
    this.textColor = dto.textColor;
    this.isComingSoonSource = dto.isComingSoon;
    this.isArchivalSource = dto.isArchival;
    this.isDeleted = dto.isDeleted;
  }

  public id = '';

  public logo = '';

  public logoId = '';

  public mapData = '';

  public creatorName = '';

  public name = '';

  public descriptionSource = '';

  public inspiredBy = '';

  public author = '';

  public durationSource: number | null = null;

  public distanceSource: number | null = null;

  public minAgeSource: number | null = null;

  public backgroundColor = '';

  public textColor = '';

  public isComingSoonSource = false;

  public isArchivalSource = false;

  public idEvent: number | null = null;

  public isDeleted = false;

  public get description() {
    return this.descriptionSource.substring(1, 32);
  }

  public get duration() {
    return `${this.durationSource} мин`;
  }

  public get minAge() {
    return `${this.minAgeSource} +`;
  }

  public get distance() {
    return `${this.distanceSource} км`;
  }

  public get tableRowTextColor() {
    if (this.isDeleted) {
      return '--disabled';
    }

    return '--typo-primary';
  }

  public get isComingSoon() {
    return this.isComingSoonSource ? 'да' : 'нет';
  }

  public get isArchival() {
    return this.isArchivalSource ? 'да' : 'нет';
  }

  public setAsRestored() {
    this.isDeleted = false;
  }

  public setAsDeleted() {
    this.isDeleted = true;
  }
}
