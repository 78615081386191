import ITextProps from '../ITextProps';

import { FontWeight } from 'shared/enums';

import c from './textThCell.module.scss';

const TextThCell = ({ children, className, color, text, textAlign, fontWeight, noWrap, onClick }: ITextProps) => {
  return (
    <div
      onClick={onClick}
      className={`${className || ''} ${c.textThCell}`}
      style={{
        textAlign: textAlign || 'initial',
        fontWeight: fontWeight || FontWeight.Light,
        color: `var(${color})`,
        whiteSpace: noWrap ? 'nowrap' : undefined,
      }}>
      {text || children}
    </div>
  );
};

export default TextThCell;
