import { observer } from 'mobx-react';
import { Spinner, TopTabs } from 'components';

import { Colors, Info, MapWrapper, CheckPoints, Preview } from './components';

import { performanceStore } from 'stores';

import { PerformanceTab } from 'shared/enums';

import c from './performanceForm.module.scss';
import { useEffect } from 'react';
import { TextL, TextS } from '../../../../../components/TextWrappers';

const PerformanceForm = () => {
  useEffect(() => {
    performanceStore.getSeasons();
  }, []);

  return (
    <div className={c.performanceWrapper}>
      <div className={c.infoWrapper}>
        <TopTabs
          className={c.tabs}
          tabItems={[
            { id: PerformanceTab.CommonInfo, name: 'Общая информация' },
            { id: PerformanceTab.Colors, name: 'Цветовое оформление' },
            { id: PerformanceTab.CheckPoints, name: 'Контрольные точки' },
          ]}
          onClick={performanceStore.performance.selectTab}
          selectedTabId={performanceStore.performance.selectedTab}
        />

        {performanceStore.performance.selectedTab === PerformanceTab.CommonInfo && <Info />}
        {performanceStore.performance.selectedTab === PerformanceTab.Colors && <Colors />}
        {performanceStore.performance.selectedTab === PerformanceTab.CheckPoints && <CheckPoints />}
      </div>

      {performanceStore.performance.selectedTab !== PerformanceTab.CheckPoints && <Preview />}
      {performanceStore.performance.selectedTab === PerformanceTab.CheckPoints && <MapWrapper />}

      {performanceStore.performance.checkpoints.length === 0 && (
        <TextS className={c.validationErrorMessage} text={'Добавьте как минимум одну контрольную точку'} />
      )}
      {performanceStore.performance.selectedTab === PerformanceTab.CheckPoints && (
        <TextS
          className={c.validationNoticeMessage}
          text={'Перейдите на вкладку с общей информацией чтобы сохранить'}
        />
      )}
    </div>
  );
};

export default observer(PerformanceForm);
