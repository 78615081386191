import React from 'react';

import { CircularProgress } from '@mui/material';

import { UIElemSizes } from 'shared/enums';

import c from './spinner.module.scss';

interface IProps {
  className?: string;
  isLoading: boolean;
  size?: UIElemSizes;
}

const Spinner = (props: IProps) => {
  const { className, isLoading, size } = props;

  let sizedClass = c.spinnerMd;

  if (size === UIElemSizes.Sm!) {
    sizedClass = c.spinnerSm;
  }

  if (size === UIElemSizes.Lg!) {
    sizedClass = c.spinnerLg;
  }

  return isLoading ? (
    <div className={c.spinnerWrapper}>
      <CircularProgress disableShrink={true} className={className ? `${sizedClass} ${className}` : sizedClass} />
    </div>
  ) : null;
};

export default Spinner;
