import React from 'react';

import { Button } from '@mui/material';

import { TextButton } from '../../TextWrappers';

import { UIElemSizes } from 'shared/enums';

import c from './primaryButton.module.scss';

interface IProps {
  caption?: string;
  isPending?: boolean;

  onClick(event?: React.MouseEvent<HTMLButtonElement>): void;
  disabled?: boolean;
  size?: UIElemSizes;

  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

const PrimaryButton = (props: IProps) => {
  const { onClick, disabled, caption, startIcon, endIcon } = props;

  return (
    <Button
      className={c.primaryButton}
      variant="contained"
      disableRipple={true}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}>
      <TextButton text={caption} />
    </Button>
  );
};

export default PrimaryButton;
