import { observer } from 'mobx-react';
import { HexColorPicker } from 'react-colorful';

import { TextM } from 'components/TextWrappers';
import { FormRow, TextInput } from 'components';

import { performanceStore } from 'stores';

import c from './colors.module.scss';

const Colors = () => {
  return (
    <div className={c.colorToolsWrapper}>
      <FormRow
        additionalClass={c.colorParamRow}
        label={'Цвет текста'}
        input={
          <div className={c.colorControlWrapper}>
            <div className={c.manualInput}>
              <TextM unselectable={true} text={'Hex'} />
              <TextInput
                className={c.colorHexInput}
                value={performanceStore.performance.textColor}
                onChange={performanceStore.performance.onChangeTextColor}
              />
            </div>

            <HexColorPicker
              color={performanceStore.performance.textColor}
              onChange={performanceStore.performance.onChangeTextColor}
            />
          </div>
        }
      />

      <FormRow
        additionalClass={c.colorParamRow}
        label={'Цвет фона'}
        input={
          <div className={c.colorControlWrapper}>
            <div className={c.manualInput}>
              <TextM unselectable={true} text={'Hex'} />
              <TextInput
                className={c.colorHexInput}
                value={performanceStore.performance.backgroundColor}
                onChange={performanceStore.performance.onChangeBackgroundColor}
              />
            </div>
            <HexColorPicker
              color={performanceStore.performance.backgroundColor}
              onChange={performanceStore.performance.onChangeBackgroundColor}
            />
          </div>
        }
      />
    </div>
  );
};

export default observer(Colors);
