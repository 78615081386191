import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import { Shows, Tickets, Users, Info, UserInfoForm } from './components';

import { IconBtn, Modal, TopTabs } from 'components';
import { TextL, TextM } from 'components/TextWrappers';

import { appStore, authUserStore } from 'stores';

import { appRoutes } from 'shared/constants/appRoutes';

import { TabNames } from 'shared/enums';

import c from './mainPage.module.scss';
import performanceStore from '../../stores/performanceStore';

const MainPage = () => {
  const history = useHistory();

  useEffect(() => {
    appStore.init();
  }, []);

  const onLogOut = () => {
    const isSuccess = authUserStore.logOut();
    if (!isSuccess) return;

    history.push(appRoutes.auth);
  };

  return (
    <div className={c.mainPageWrapper}>
      <div className={c.topControls}>
        <div className={c.contentTabs}>
          <TopTabs
            tabItems={appStore.tabs}
            onClick={appStore.onSelectTab}
            selectedTabId={appStore.selectedTabId as string}
          />
        </div>

        <div className={c.userPanel}>
          <IconBtn disabled={true} icon={<AccountCircleIcon />} onClick={authUserStore.showUserInfo} />
          <TextM className={c.loginTitle} text={'Логин:'} />
          <TextM noWrap={true} text={authUserStore.userInfoModel.email} />
          <IconBtn className={c.logoutBtn} icon={<LogoutIcon />} onClick={onLogOut} />
        </div>
      </div>

      <div className={c.contentWrapper}>
        {appStore.selectedTabId === TabNames.Users && <Users />}
        {appStore.selectedTabId === TabNames.Shows && <Shows />}
        {appStore.selectedTabId === TabNames.Tickets && <Tickets />}
        {appStore.selectedTabId === TabNames.Info && <Info />}
      </div>

      <Modal
        confirmCaption={'Сохранить'}
        cancelCaption={'Отмена'}
        onClose={authUserStore.closeUserInfo}
        onConfirm={authUserStore.submitUserInfoChanged}
        isOpened={authUserStore.isShowUserInfo}>
        <UserInfoForm />
      </Modal>
    </div>
  );
};

export default observer(MainPage);
