import { makeAutoObservable } from 'mobx';
import { ITheatreInfoGetDto, ITheatreInfoPutDto } from '../../interfaces/api/theatreInfo/ITheatreInfo';

export class TheatreInfoModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public history = '';

  public mission = '';

  public imageId = '';

  public imageFile: any = null;

  public previewImage = '';

  public setImageId(id: string) {
    this.imageId = id;
  }

  public setImage(event: any) {
    this.imageFile = event.target.files[0];

    this.previewImage = URL.createObjectURL(event.target.files[0]);
    event.target.value = '';
  }

  public onChangeMission(value: string) {
    this.mission = value;
  }

  public onChangeHistory(value: string) {
    this.history = value;
  }

  public init(dto: ITheatreInfoGetDto) {
    this.history = dto.history;
    this.mission = dto.mission;
    this.imageId = dto.mainImage.id;
  }

  public getPutDto(): ITheatreInfoPutDto {
    return { mission: this.mission, history: this.history, mainImageId: this.imageId };
  }

  public deInit() {
    this.imageId = '';
    this.mission = '';
    this.history = '';
    this.previewImage = '';
    this.imageFile = null;
  }
}
