import React from 'react';

import { Checkbox } from '@mui/material';

import c from './checkbox.module.scss';

interface IProps {
  disabled?: boolean;
  checked: boolean;
  indeterminate?: boolean;
  onChange?(value: boolean): void;
}

const CheckBox = (props: IProps) => {
  const { disabled, checked, indeterminate, onChange } = props;

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    onChange(event.target.checked as boolean);
  };

  return (
    <Checkbox
      className={disabled ? `${c.checkboxWrapper} ${c.disabled}` : `${c.checkboxWrapper} ${c.active}`}
      onChange={onChangeValue}
      indeterminate={indeterminate}
      disabled={disabled}
      checked={checked}
    />
  );
};

export default CheckBox;
