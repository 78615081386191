import { makeAutoObservable } from 'mobx';

import { fileApiService, performancesApiService } from 'apiServices';

import { appStore } from './index';

import { IPerformanceStoreDI } from 'shared/interfaces/app/di';
import { PerformanceModel, PerformanceRequestParamsControl, PerformanceRowModel } from 'shared/models/performances';
import { IFileDto } from '../shared/interfaces/api/files/IFileDto';
import seasonsApiService from '../apiServices/SeasonsApiService';
import { SnackBarStates, TabNames } from '../shared/enums';
import { Debounce } from '../shared/models/serve';

class PerformanceStore implements IPerformanceStoreDI {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public debounce = new Debounce();

  private startRecordNumber = 0;

  public totalDataLength = 0;

  public performanceIdsToRemove: string[] = [];

  public performanceIdToRestore = '';

  public isShowForm = false;

  public performance = new PerformanceModel();

  public performances: PerformanceRowModel[] = [];

  public requestParamsControl = new PerformanceRequestParamsControl(this, 15);

  public get selectList() {
    const list = this.performances.filter((item) => !item.isDeleted).map((item) => ({ id: item.id, name: item.name }));

    return list;
  }

  public get performanceNameToRemove() {
    if (this.performanceIdsToRemove.length > 1) return '-';
    const performance = this.performances.find((item) => item.id === this.performanceIdsToRemove[0]);
    if (!performance) return '-';

    return `${performance.name}`;
  }

  public get performanceNameToRestore() {
    const performance = this.performances.find((item) => item.id === this.performanceIdToRestore);
    if (!performance) return '-';

    return `${performance.name}`;
  }

  public async setStartRecordNumber(startFrom: number, preventRequest?: boolean) {
    this.startRecordNumber = startFrom;

    if (startFrom === 0) {
      this.performances = [];
    }

    if (preventRequest) return;

    const isTimeToGetRequest = await this.debounce.getTerm(
      this.requestParamsControl.filtersControl.hasAnyAppliedFilters ? 500 : 0
    );
    if (!isTimeToGetRequest) return;

    await this.getPerformances();
  }

  public closeForm() {
    this.isShowForm = false;
    this.performance.deInit();
  }

  public openForm() {
    this.isShowForm = true;
  }

  public showRemoveDialog(performanceId: string) {
    this.performanceIdsToRemove = [performanceId];
  }

  public closeRemoveDialog() {
    this.performanceIdsToRemove = [];
  }

  public showRestoreDialog(userId: string) {
    this.performanceIdToRestore = userId;
  }

  public closeRestoreDialog() {
    this.performanceIdToRestore = '';
  }

  public getNextPage() {
    this.startRecordNumber = this.performances.length;
    this.getPerformances();
  }

  public async getPerformances() {
    try {
      this.isPending = true;

      const queryParams = this.requestParamsControl.getRequestParams();
      const performancesDto = await performancesApiService.getPerformances(queryParams);
      if (!performancesDto) return;

      this.totalDataLength = performancesDto.totalCount;

      const newPageData = performancesDto.models.map((dto) => new PerformanceRowModel(dto));

      this.performances.push(...newPageData);
    } catch (e) {
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public imgSrc = '';

  public async getSeasons() {
    try {
      this.isPending = true;
      const dto = await seasonsApiService.getSeasons();

      this.performance.setSeasons(dto.models);
    } catch (e) {
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public async getPerformance(performanceId: string) {
    try {
      this.isPending = true;
      const dto = await performancesApiService.getPerformanceById(performanceId);

      this.performance.initFromDto(dto);
    } catch (e) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при получении списка спектаклей');
    } finally {
      this.isPending = false;
    }
  }

  public async submitPerformance() {
    if (this.performance.logoFile) {
      const result = await this.postLogoImageFile();

      if (result) {
        this.performance.setLogoId(result.id);
      }
    }

    if (this.performance.previewMapImage) {
      const result = await this.postMapImgeFile();

      if (result) {
        this.performance.setMapPreviewId(result.id);
      }
    }

    if (this.performance.id) {
      await this.updatePerformance();
      this.setStartRecordNumber(0);
    }

    if (!this.performance.id) {
      await this.createPerformance();
      this.setStartRecordNumber(0);
    }

    this.closeForm();
  }

  public async createPerformance() {
    try {
      this.isPending = true;
      const postDto = this.performance.getPostDto();

      const result = await performancesApiService.postPerformance(postDto);

      appStore.showSnackbar(SnackBarStates.Success, 'Спектакль успешно создан');
    } catch (e) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при добавлении спектакля');
    } finally {
      this.isPending = false;
    }
  }

  public async updatePerformance() {
    try {
      this.isPending = true;
      const putDto = this.performance.getPutDto();
      await performancesApiService.putPerformance(putDto);
      appStore.showSnackbar(SnackBarStates.Success, 'Спектакль успешно обновлён');
    } catch (e) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при изменении спектакля');
    } finally {
      this.isPending = false;
    }
  }

  public async restorePerformance() {
    try {
      this.isPending = true;
      await performancesApiService.restorePerformance(this.performanceIdToRestore);
      const found = this.performances.find((item) => item.id === this.performanceIdToRestore);
      if (found) {
        found.setAsRestored();
      }

      appStore.showSnackbar(SnackBarStates.Success, 'Спектакль успешно восстановлен');

      this.closeRestoreDialog();
    } catch (e) {
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при восстановлении спектакля');
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public async postLogoImageFile(): Promise<IFileDto | void> {
    try {
      this.isPending = true;
      const formData = new FormData();
      formData.append('file', this.performance.logoFile);
      const result = await fileApiService.postFile(formData);
      return result;
    } catch (e) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при загрузке файла');
    } finally {
      this.isPending = false;
    }
  }

  public async postMapImgeFile(): Promise<IFileDto | void> {
    try {
      this.isPending = true;
      const formData = new FormData();
      formData.append('file', this.performance.mapFile);
      const result = await fileApiService.postFile(formData);
      return result;
    } catch (e) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при загрузке файла');
    } finally {
      this.isPending = false;
    }
  }

  public async removePerformance() {
    try {
      this.isPending = true;
      await performancesApiService.deletePerformance(this.performanceIdsToRemove[0]);
      const found = this.performances.find((item) => item.id === this.performanceIdsToRemove[0]);

      if (found) {
        found.setAsDeleted();
      }
      this.performanceIdsToRemove = [];
      appStore.showSnackbar(SnackBarStates.Success, 'Спектакль успешно удалён');
    } catch (e) {
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при удалении спектакля');
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public deInit(preventRequest?: boolean) {
    this.performance.deInit();
    this.requestParamsControl.deInit(TabNames.Shows, preventRequest);

    this.isPending = false;
    this.performances = [];
    this.startRecordNumber = 0;
    this.totalDataLength = 0;
    this.performanceIdsToRemove = [];
    this.performanceIdToRestore = '';
    this.isShowForm = false;

    this.debounce.deInitDebounce();
  }
}

export default new PerformanceStore();
