import React, { ReactElement } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { PrimaryButton, SecondaryButton } from 'components/Buttons';
import { TextL } from '../TextWrappers';

import c from './modal.module.scss';

interface IProps {
  className?: string;
  cancelCaption?: string;
  children?: ReactElement;
  onClose(): void;
  confirmCaption?: string;
  onConfirm(): void;
  onConfirmDisabled?: boolean;
  isOpened: boolean;
  title?: string;
}

const Modal = (props: IProps) => {
  const { cancelCaption, className, children, onClose, confirmCaption, onConfirm, onConfirmDisabled, isOpened, title } =
    props;

  return (
    <Dialog open={isOpened} className={`${c.modalWindow} ${className || ''}`} onClose={onClose}>
      <DialogTitle className={c.dialogTitle}>
        <TextL text={title} />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          paddingRight: 3,
          paddingBottom: 2,
        }}>
        {!!cancelCaption && <SecondaryButton caption={cancelCaption || 'Нет'} onClick={onClose} />}

        <PrimaryButton disabled={onConfirmDisabled} caption={confirmCaption || 'Да'} onClick={onConfirm} />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
