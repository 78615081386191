import { makeAutoObservable } from 'mobx';

import dayjs from 'dayjs';

import { IUsersStoreDI } from '../../interfaces/app/di';
import { IUsersAccountParamsPostDto } from '../../interfaces/api/users';
import { UserType } from '../../enums';

export enum SelectedGroup {
  All = 'all',
  SelectedOnly = 'SelectedOnly',
  UnselectedOnly = 'UnselectedOnly',
}

export default class UsersAccountsFiltersControl {
  constructor(private usersStore: IUsersStoreDI) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public firstNamePattern = '';

  public secondNamePattern = '';

  public lastNamePattern = '';

  public eMailPattern = '';

  public confirmedState: SelectedGroup = SelectedGroup.All;

  public deletedState: SelectedGroup = SelectedGroup.All;

  public isConfirmed: boolean | null = null;

  public isBlocked: boolean | null = null;

  public isDeleted: boolean | null = null;

  public userType: UserType = UserType.Admin;

  public registrationDateFrom = '';

  public registrationDateTo = '';

  public init(initParams: IUsersAccountParamsPostDto) {
    this.firstNamePattern = initParams.firstName;
    this.secondNamePattern = initParams.secondName;
    this.lastNamePattern = initParams.lastName;
    this.eMailPattern = initParams.email;
    this.registrationDateFrom = initParams.registrationDateFrom;
    this.registrationDateTo = initParams.registrationDateTo;
    this.isConfirmed = initParams.isConfirmed === null ? null : initParams.isConfirmed?.toString() === 'true';
    this.isDeleted = initParams.isDeleted === null ? null : initParams.isDeleted?.toString() === 'true';

    this.deletedState =
      initParams.isDeleted === null
        ? SelectedGroup.All
        : initParams.isDeleted?.toString() === 'true'
        ? SelectedGroup.SelectedOnly
        : SelectedGroup.UnselectedOnly;

    this.confirmedState =
      initParams.isConfirmed === null
        ? SelectedGroup.All
        : initParams.isConfirmed?.toString() === 'true'
        ? SelectedGroup.SelectedOnly
        : SelectedGroup.UnselectedOnly;
  }

  public get hasAnyAppliedFilters() {
    return (
      !!this.lastNamePattern ||
      !!this.firstNamePattern ||
      !!this.secondNamePattern ||
      !!this.eMailPattern ||
      this.confirmedState !== SelectedGroup.All ||
      this.deletedState !== SelectedGroup.All ||
      !!this.registrationDateFrom ||
      !!this.registrationDateTo
    );
  }

  public setUserType(userType: UserType) {
    this.userType = userType;
  }

  public onSelectConfirmed(selected: SelectedGroup) {
    this.confirmedState = selected;
    this.isConfirmed =
      selected === SelectedGroup.SelectedOnly ? true : selected === SelectedGroup.UnselectedOnly ? false : null;

    this.usersStore.setStartRecordNumber(0);
  }

  public onSelectDeleted(selected: SelectedGroup) {
    this.deletedState = selected;
    this.isDeleted =
      selected === SelectedGroup.SelectedOnly ? true : selected === SelectedGroup.UnselectedOnly ? false : null;

    this.usersStore.setStartRecordNumber(0);
  }

  public dropDatesFilter() {
    this.onSelectRegistrationFrom(null);
    this.onSelectRegistrationTo(null);

    this.usersStore.setStartRecordNumber(0);
  }

  public onSelectRegistrationFrom(date: Date | null) {
    if (!date) {
      this.registrationDateFrom = '';
      return;
    }

    if (date) {
      const timeZoneOffset = Math.abs(date?.getTimezoneOffset());
      this.registrationDateFrom = dayjs(date).add(timeZoneOffset, 'minute').format('MM-DD-YYYY');
    }

    this.usersStore.setStartRecordNumber(0);
  }

  public onSelectRegistrationTo(date: Date | null) {
    if (!date) {
      this.registrationDateTo = '';
      return;
    }

    if (date) {
      const timeZoneOffset = Math.abs(date?.getTimezoneOffset());
      this.registrationDateTo = dayjs(date).add(timeZoneOffset, 'minute').format('MM-DD-YYYY');
    }
    this.usersStore.setStartRecordNumber(0);
  }

  public onChangeFirstNamePattern(firstNamePattern: string) {
    this.firstNamePattern = firstNamePattern;
    this.usersStore.setStartRecordNumber(0);
  }

  public onChangeSecondNamePattern(secondNamePattern: string) {
    this.secondNamePattern = secondNamePattern;
    this.usersStore.setStartRecordNumber(0);
  }

  public onChangeLastNamePattern(lastNamePattern: string) {
    this.lastNamePattern = lastNamePattern;
    this.usersStore.setStartRecordNumber(0);
  }

  public onChangeEmailPattern(eMailPattern: string) {
    this.eMailPattern = eMailPattern;
    this.usersStore.setStartRecordNumber(0);
  }

  public deInit(preventRequest?: boolean) {
    this.firstNamePattern = '';
    this.secondNamePattern = '';
    this.lastNamePattern = '';
    this.eMailPattern = '';
    this.registrationDateFrom = '';
    this.registrationDateTo = '';

    this.confirmedState = SelectedGroup.All;
    this.deletedState = SelectedGroup.All;

    this.isDeleted = null;
    this.isConfirmed = null;

    this.usersStore.setStartRecordNumber(0, preventRequest);
  }
}
