import { observer } from 'mobx-react';

import { TextL, TextM } from 'components/TextWrappers';

import { authUserStore } from 'stores';

import { ConfirmPageMode } from 'shared/enums';

import c from './firstConfirmPage.module.scss';

const FirstConfirmPage = () => {
  let firstText = '';
  let secondText = '';

  if (authUserStore.confirmPageMode === ConfirmPageMode.AfterRegistration) {
    firstText = 'Ссылка для подтверждения регистрации направлена на почту';
    secondText = 'Пройдите по ссылке из письма, чтобы закончить регистрацию';
  }

  if (authUserStore.confirmPageMode === ConfirmPageMode.AfterPasswordRecovering) {
    firstText = 'Ссылка для подтверждения изменения пароля направлена на почту';
    secondText = 'Пройдите по ссылке из письма, чтобы закончить процедуру изменения пароля';
  }

  if (authUserStore.confirmPageMode === ConfirmPageMode.NotAvailable) {
    firstText = '';
    secondText = '';
  }

  return (
    <div className={c.resultWrapper}>
      <TextL text={firstText} />
      <div className={c.userEmail}>
        <TextM text={authUserStore.lastEmail} />
      </div>
      <TextL text={secondText} />
    </div>
  );
};

export default observer(FirstConfirmPage);
