import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { PerformanceTab, SelectedGroup, TabNames, UIElemSizes } from 'shared/enums';

import { IconBtn, Modal, PrimaryButton, Select, Spinner, TabHeadLine, VirtualTable } from 'components';
import { TextM, TextTbCell } from 'components/TextWrappers';

import { performanceStore } from 'stores';

import { IVirtualTableColumn } from 'shared/interfaces/app';

import { PerformanceRowModel } from 'shared/models/performances';

import c from './shows.module.scss';

import PerformanceForm from './PerformanceForm';

import { Delete, Edit, RestoreFromTrash } from '@mui/icons-material';
import { TooltipPlacement } from '../../../../components/Buttons/IconButton/IconBtn';
import { performancesApiService } from '../../../../apiServices';

const Performances = () => {
  useEffect(() => {
    performanceStore.debounce.deInitDebounce();
    performanceStore.requestParamsControl.initFromURL(TabNames.Shows);
    performanceStore.getPerformances();

    return () => performanceStore.deInit();
  }, []);

  const columns: IVirtualTableColumn[] = [
    {
      dataField: 'name',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Название',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'inspiredBy',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Основано на произведении',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'description',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Описание',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'duration',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Длительность',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'distance',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Длина маршрута',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'minAge',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Ограничение по возрасту',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: '',
      columnWidth: '5%',
      name: 'Логотип',
      renderCustomCell: (row: PerformanceRowModel) => (
        <div className={c.logoWrapper}>
          <img className={c.logoInTable} src={`${performancesApiService.host}/images/${row.logoId}`} />
        </div>
      ),
    },
    {
      dataField: 'backgroundColor',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Цвет фона',
      sortControl: performanceStore.requestParamsControl.sortControl,
      renderCustomCell: (row: PerformanceRowModel) => (
        <div
          className={c.bgColorExample}
          style={{ backgroundColor: row.isDeleted ? '#C9C9C9FF' : row.backgroundColor }}>
          <TextTbCell hexColor={row.isDeleted ? '#C9C9C9FF' : row.textColor} text={'Цвет'} />
        </div>
      ),
    },
    {
      dataField: 'textColor',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Цвет текста',
      sortControl: performanceStore.requestParamsControl.sortControl,
      renderCustomCell: (row: PerformanceRowModel) => (
        <TextTbCell hexColor={row.isDeleted ? '#C9C9C9FF' : row.textColor} text={'Цвет текста'} />
      ),
    },
    {
      dataField: 'isComingSoon',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Скоро',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'isArchival',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Архивный',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'creatorName',
      columnWidth: '10%',
      minWidth: '110px',
      name: 'Добавил',
      sortControl: performanceStore.requestParamsControl.sortControl,
    },
    {
      dataField: 'isDeleted',
      columnWidth: '5%',
      name: '',
      filtered: performanceStore.requestParamsControl.filtersControl.deletedState !== SelectedGroup.All,
      sortControl: performanceStore.requestParamsControl.sortControl,
      onRemoveFilter: () => performanceStore.requestParamsControl.filtersControl.onSelectDeleted(SelectedGroup.All),
      filterControl: () => {
        return (
          <div className={c.columnSelectFilterWrapper}>
            <Select
              items={[
                { id: SelectedGroup.All, name: 'Все' },
                { id: SelectedGroup.SelectedOnly, name: 'Только удалённые' },
                { id: SelectedGroup.UnselectedOnly, name: 'Только не удалённые' },
              ]}
              onChange={performanceStore.requestParamsControl.filtersControl.onSelectDeleted}
              value={performanceStore.requestParamsControl.filtersControl.deletedState}
            />
          </div>
        );
      },
      renderCustomCell: (row: PerformanceRowModel) => {
        const onRemove = () => {
          performanceStore.showRemoveDialog(row.id);
        };

        const onEdit = () => {
          performanceStore.getPerformance(row.id);
        };

        const onRestore = () => {
          performanceStore.showRestoreDialog(row.id);
        };

        return (
          <>
            <IconBtn
              className={c.btn}
              disabled={row.isDeleted}
              hintPlacement={TooltipPlacement.Top}
              icon={<Edit />}
              hint={'Редактировать спектакль'}
              onClick={onEdit}
            />
            {
              <div className={c.checkBoxWrapper}>
                {row.isDeleted ? (
                  <IconBtn
                    className={c.btn}
                    icon={<RestoreFromTrash />}
                    hint={'Восстановить спектакль'}
                    hintPlacement={TooltipPlacement.Top}
                    onClick={onRestore}
                  />
                ) : (
                  <IconBtn
                    className={c.btn}
                    hintPlacement={TooltipPlacement.Top}
                    icon={<Delete />}
                    hint={'Удалить спектакль'}
                    onClick={onRemove}
                  />
                )}
              </div>
            }
          </>
        );
      },
    },
  ];

  return (
    <div className={c.showsWrapper}>
      <Spinner isLoading={performanceStore.isPending} size={UIElemSizes.Lg} />
      <TabHeadLine
        name={TabNames.Shows}
        isPending={false}
        freeContent={
          <div className={c.addBtnWrapper}>
            <PrimaryButton caption={'Добавить'} onClick={performanceStore.openForm} />
          </div>
        }
      />
      <div className={c.contentWrapper}>
        <VirtualTable
          columns={columns}
          onRowDoubleClick={performanceStore.openForm}
          data={performanceStore.performances}
          getNextPage={performanceStore.getNextPage}
          hasAnyAppliedFilters={performanceStore.requestParamsControl.filtersControl.hasAnyAppliedFilters}
          totalDataLength={performanceStore.totalDataLength}
          onRemoveAllFilters={performanceStore.requestParamsControl.filtersControl.deInit}
        />
      </div>

      <Modal
        confirmCaption={'Восстановить'}
        cancelCaption={'Отмена'}
        onClose={performanceStore.closeRestoreDialog}
        onConfirm={performanceStore.restorePerformance}
        isOpened={!!performanceStore.performanceIdToRestore}>
        <TextM
          className={c.confirmDialog}
          text={`Восстановить спектакль: ${performanceStore.performanceNameToRestore}?`}
        />
      </Modal>

      <Modal
        confirmCaption={'Удалить'}
        cancelCaption={'Отмена'}
        onClose={performanceStore.closeRemoveDialog}
        onConfirm={performanceStore.removePerformance}
        isOpened={performanceStore.performanceIdsToRemove.length > 0}>
        <TextM className={c.confirmDialog} text={`Удалить спектакль: "${performanceStore.performanceNameToRemove}?"`} />
      </Modal>

      <Modal
        className={c.modalWrapper}
        onClose={performanceStore.closeForm}
        onConfirm={performanceStore.submitPerformance}
        onConfirmDisabled={performanceStore.performance.selectedTab === PerformanceTab.CheckPoints}
        confirmCaption={'Сохранить'}
        cancelCaption={'Отмена'}
        isOpened={performanceStore.isShowForm || !!performanceStore.performance.id}>
        <PerformanceForm />
      </Modal>

      <div className={c.bottomSpace}></div>
    </div>
  );
};

export default observer(Performances);
