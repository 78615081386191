import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { TextField } from '@mui/material';

import { IconBtn } from '../Buttons';
import { ValidStatus } from 'shared/models/serve/ValidationModel';

import c from './textinput.module.scss';

interface IProps {
  className?: string;
  disabled?: boolean;
  validStatus?: ValidStatus;
  isHasClearBtn?: boolean;
  placeholder?: string;
  password?: boolean;
  value: string;
  startAdornment?: JSX.Element | null;
  endAdornment?: JSX.Element | null;
  shouldPrevent?: boolean;
  onChange(value: string): void;
  onBlur?(): void;
  autoFocus?: boolean;
}

const TextInput = (props: IProps) => {
  const {
    className,
    shouldPrevent,
    autoFocus,
    disabled,
    validStatus,
    isHasClearBtn,
    value,
    password,
    placeholder,
    startAdornment,
    endAdornment,
    onChange,
    onBlur,
  } = props;

  const onChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    onChange(event.target.value as string);
  };

  const clearValue = () => {
    onChange('');
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (!shouldPrevent) return;
    e.stopPropagation();
  };

  let validStateClassName = '';

  switch (validStatus) {
    case ValidStatus.Unknown: {
      break;
    }

    case ValidStatus.Success: {
      validStateClassName += ` ${c.success}`;
      break;
    }

    case ValidStatus.Error: {
      validStateClassName += ` ${c.error}`;
      break;
    }

    case ValidStatus.Warning: {
      validStateClassName += ` ${c.warning}`;
      break;
    }
  }

  const clearBtn = <IconBtn className={c.clrBtn} icon={<CloseIcon className={c.clrBtnIcn} />} onClick={clearValue} />;

  return (
    <TextField
      onMouseDown={onMouseDown}
      autoComplete={'off'}
      autoFocus={autoFocus}
      className={`${c.textInput} ${validStateClassName} ${className ? className : ''}`}
      onChange={onChangeValue}
      onBlur={onBlur}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      variant="outlined"
      type={password ? 'password' : 'text'}
      size="small"
      fullWidth
      InputProps={{
        startAdornment,
        endAdornment: endAdornment ? endAdornment : isHasClearBtn && !!value ? clearBtn : null,
      }}
    />
  );
};

export default TextInput;
