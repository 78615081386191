import { makeAutoObservable } from 'mobx';
import ValidationModel, { Rule, ValidStatus } from '../serve/ValidationModel';

import { passwordRegexRule } from '../../constants/regegRules';
import {IRecoveryPasswordPutDto} from "../../interfaces/api/authUser";

class UserRecoveryPasswordModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public validation = new ValidationModel([
    {
      rules: [
        { rule: Rule.MinLength, description: 'Минимальная длина 1 символ', payload: 1 },
        { rule: Rule.MaxLength, description: 'Максимальная длина 128 символов', payload: 128 },
      ],
      validStatus: ValidStatus.Unknown,
    },
    {
      rules: [
        {
          rule: Rule.Regex,
          regexRule: passwordRegexRule,
          description:
            'Пароль должен содержать цифру, только латинские буквы в разных регистрах. Мин. длина пароля 6 символов. Пример: Password-01-01-1970',
        },
      ],
      validStatus: ValidStatus.Unknown,
    },
    {
      rules: [
        {
          rule: Rule.Regex,
          regexRule: passwordRegexRule,
          description:
            'Пароль должен содержать цифру, только латинские буквы в разных регистрах. Мин. длина пароля 6 символов. Пример: Password-01-01-1970',
        },
      ],
      validStatus: ValidStatus.Unknown,
    },
  ]);

  public email = '';

  public newPassword = '';

  public repeatedNewPassword = '';

  public isShowPassword = false;

  public isShowRepeatedPassword = false;

  public showPassword() {
    this.isShowPassword = true;
  }

  public hidePassword() {
    this.isShowPassword = false;
  }

  public showRepeatedPassword() {
    this.isShowRepeatedPassword = true;
  }

  public hideRepeatedPassword() {
    this.isShowRepeatedPassword = false;
  }

  public onTypeNewPassword(value: string) {
    this.newPassword = value;
    this.checkForValid();
  }

  public onTypeRepeatedNewPassword(value: string) {
    this.repeatedNewPassword = value;
    this.checkForValid();
  }

  public onTypeEmail(value: string) {
    this.email = value;
    this.checkForValid();
  }

  private checkForValid() {
    this.validation.checkForValid([this.email, this.newPassword, this.repeatedNewPassword]);
    this.checkPasswordsForEquals();
  }

  private checkPasswordsForEquals() {
    if (this.validation.isValidationActivated && this.newPassword !== this.repeatedNewPassword) {
      this.validation.checkRequirements[1].currentNotice = 'Пароли не совпадают!';
      this.validation.checkRequirements[1].validStatus = ValidStatus.Warning;
      this.validation.checkRequirements[2].currentNotice = 'Пароли не совпадают!';
      this.validation.checkRequirements[2].validStatus = ValidStatus.Warning;
      this.validation.modelIsNotValid = true;
    }
  }

  public getPutDto(): IRecoveryPasswordPutDto | null {
    this.validation.activateValidation([this.email, this.newPassword, this.repeatedNewPassword]);
    this.checkPasswordsForEquals();

    if (this.validation.modelIsNotValid) return null;

    return {
      email: this.email,
      newPassword: this.newPassword,
    };
  }

  public deInit() {
    this.email = '';
    this.newPassword = '';
    this.repeatedNewPassword = '';
    this.validation.deInit();
  }
}

export default UserRecoveryPasswordModel;
