import ITextProps from '../ITextProps';

import { FontWeight } from 'shared/enums';

import c from './textM.module.scss';

const TextM = ({
  children,
  className,
  color,
  fontWeight,
  text,
  noWrap,
  textAlign,
  onClick,
  unselectable,
}: ITextProps) => {
  const classes = unselectable ? `${className || ''} ${c.textM} unselectable` : `${className || ''} ${c.textM}`;

  return (
    <div
      onClick={onClick}
      className={classes}
      style={{
        textAlign: textAlign || 'initial',
        fontWeight: fontWeight || FontWeight.Light,
        color: `var(${color})`,
        whiteSpace: noWrap ? 'nowrap' : undefined,
      }}>
      {text || children}
    </div>
  );
};

export default TextM;
