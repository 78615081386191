import { makeAutoObservable } from 'mobx';

import LocalStorageService from 'services/LocalStorageService';

import ValidationModel, { Rule, ValidStatus } from '../serve/ValidationModel';

import { UserUpdateMode } from '../../enums';

import { passwordRegexRule } from '../../constants';
import { IAuthResponse, IUserInfoGetDto, IUserInfoPostDto, IUserInfoPutDto } from '../../interfaces/api/authUser';

export const USER_INFO_STORAGE_KEY = 'userAuthData';

class UserInfoModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public validation = new ValidationModel([
    {
      // Фамилия
      rules: [
        { rule: Rule.MinLength, description: 'Минимальная длина 1 символ', payload: 1 },
        { rule: Rule.MaxLength, description: 'Максимальная длина 128 символов', payload: 128 },
      ],
      validStatus: ValidStatus.Unknown,
    },
    {
      // Имя
      rules: [
        { rule: Rule.MinLength, description: 'Минимальная длина 1 символ', payload: 1 },
        { rule: Rule.MaxLength, description: 'Максимальная длина 128 символов', payload: 128 },
      ],
      validStatus: ValidStatus.Unknown,
    },
    {
      // Отчество
      rules: [
        { rule: Rule.MinLength, description: 'Минимальная длина 1 символ', payload: 1 },
        { rule: Rule.MaxLength, description: 'Максимальная длина 128 символов', payload: 128 },
      ],
      validStatus: ValidStatus.Unknown,
    },
    {
      // Email
      rules: [
        { rule: Rule.MinLength, description: 'Минимальная длина 1 символ', payload: 1 },
        { rule: Rule.MaxLength, description: 'Максимальная длина 128 символов', payload: 128 },
      ],
      validStatus: ValidStatus.Unknown,
    },
    {
      // Пароль
      rules: [
        {
          rule: Rule.Regex,
          regexRule: passwordRegexRule,
          description:
            'Пароль должен содержать цифру, только латинские буквы в разных регистрах. Мин. длина пароля 6 символов. Пример: Password-01-01-1970',
        },
      ],
      validStatus: ValidStatus.Unknown,
    },
    {
      // Повторите пароль
      rules: [
        {
          rule: Rule.Regex,
          regexRule: passwordRegexRule,
          description:
            'Пароль должен содержать цифру, только латинские буквы в разных регистрах. Мин. длина пароля 6 символов. Пример: Password-01-01-1970',
        },
      ],
      validStatus: ValidStatus.Unknown,
    },
  ]);

  public registrationDate = '';

  public lastName = '';

  public firstName = '';

  public secondName = '';

  public email = '';

  public password = '';

  public repeatedPassword = '';

  public isShowPassword = false;

  public isShowRepeatedPassword = false;

  public roles: any[] = [];

  public token = '';

  public refreshToken = '';

  public initialFirstName = '';

  public initialSecondName = '';

  public initialLastName = '';

  public showPassword() {
    this.isShowPassword = true;
  }

  public hidePassword() {
    this.isShowPassword = false;
  }

  public showRepeatedPassword() {
    this.isShowRepeatedPassword = true;
  }

  public hideRepeatedPassword() {
    this.isShowRepeatedPassword = false;
  }

  public onChangeFirstName(firstName: string) {
    this.firstName = firstName;
    this.checkForValid();
  }

  public onChangeSecondName(secondName: string) {
    this.secondName = secondName;
    this.checkForValid();
  }

  public onChangeLastName(lastName: string) {
    this.lastName = lastName;
    this.checkForValid();
  }

  public onChangeEmail(email: string) {
    this.email = email;
    this.checkForValid();
  }

  public onTypePassword(value: string) {
    this.password = value;
    this.checkForValid();
  }

  public onTypeRepeatedPassword(value: string) {
    this.repeatedPassword = value;
    this.checkForValid();
  }

  private checkPasswordsForEquals() {
    if (this.validation.isValidationActivated && this.password !== this.repeatedPassword) {
      this.validation.checkRequirements[4].currentNotice = 'Пароли не совпадают!';
      this.validation.checkRequirements[4].validStatus = ValidStatus.Warning;
      this.validation.checkRequirements[5].currentNotice = 'Пароли не совпадают!';
      this.validation.checkRequirements[5].validStatus = ValidStatus.Warning;
      this.validation.modelIsNotValid = true;
    }
  }

  private checkForValid() {
    const [validationFields, withoutPassword] = this.configureAndGetValidationList();

    this.validation.checkForValid(validationFields, withoutPassword ? [4, 5] : undefined);
    if (withoutPassword) return;

    this.checkPasswordsForEquals();
  }

  public getUserPostDto(): IUserInfoPostDto | null {
    this.validation.activateValidation([
      this.lastName,
      this.firstName,
      this.secondName,
      this.email,
      this.password,
      this.repeatedPassword,
    ]);

    this.checkPasswordsForEquals();

    if (this.validation.modelIsNotValid) return null;

    return {
      firstName: this.firstName,
      secondName: this.secondName,
      lastName: this.lastName,
      email: this.email,
      password: this.password,
    };
  }

  public getUserPutDto(): IUserInfoPutDto | null {
    const [validationFields, mode] = this.configureAndGetValidationList();

    this.validation.activateValidation(validationFields, mode === UserUpdateMode.InfoOnly ? [4, 5] : undefined);

    if (mode === UserUpdateMode.InfoAndPassword || mode === UserUpdateMode.PasswordOnly) {
      this.checkPasswordsForEquals();
    }

    if (this.validation.modelIsNotValid) return null;

    return {
      lastName: this.lastName,
      firstName: this.firstName,
      secondName: this.secondName,
      email: this.email,
      password: mode === UserUpdateMode.InfoOnly ? null : this.password,
    };
  }

  private configureAndGetValidationList(): [string[], UserUpdateMode] {
    const infoAndPasswordValidationList = [
      this.lastName,
      this.firstName,
      this.secondName,
      this.email,
      this.password,
      this.repeatedPassword,
    ];

    const infoOnlyValidationList = [this.lastName, this.firstName, this.secondName, this.email];

    let validationParams: any[] = [];

    let userUpdateMode: UserUpdateMode = UserUpdateMode.InfoOnly;

    const isHasUpdateForPassword =
      (this.password.length !== 0 || this.repeatedPassword.length !== 0) && !!this.registrationDate;

    const hasInfoUpdate = this.checkInfoUpdate();

    if (isHasUpdateForPassword && !hasInfoUpdate) {
      userUpdateMode = UserUpdateMode.PasswordOnly;
      validationParams = infoAndPasswordValidationList;
    }

    if (isHasUpdateForPassword && hasInfoUpdate) {
      userUpdateMode = UserUpdateMode.InfoAndPassword;
      validationParams = infoAndPasswordValidationList;
    }

    if (!isHasUpdateForPassword && hasInfoUpdate) {
      userUpdateMode = UserUpdateMode.InfoOnly;
      validationParams = infoOnlyValidationList;
    }

    if (userUpdateMode === UserUpdateMode.InfoOnly) {
      this.validation.checkRequirements[4].validStatus = ValidStatus.Unknown;
      this.validation.checkRequirements[4].currentNotice = '';
      this.validation.checkRequirements[5].validStatus = ValidStatus.Unknown;
      this.validation.checkRequirements[5].currentNotice = '';
    }

    if (userUpdateMode === UserUpdateMode.InfoOnly || userUpdateMode === UserUpdateMode.PasswordOnly) {
      this.checkPasswordsForEquals();
    }

    return [validationParams, userUpdateMode];
  }

  public setTokens(successAuthInfo: IAuthResponse) {
    this.token = successAuthInfo.accessToken;
    this.refreshToken = successAuthInfo.accessToken;
  }

  public initBySuccessAuth(dto: IUserInfoGetDto, initFromLocalStorage?: boolean) {
    this.lastName = dto.lastName;
    this.firstName = dto.firstName;
    this.secondName = dto.secondName;
    this.email = dto.email;

    this.registrationDate = dto.registrationDate;

    this.setEditableInitialInfo(dto.firstName, dto.secondName, dto.lastName, dto.secondName);

    if (dto.token) {
      this.token = dto.token;
    }

    if (initFromLocalStorage) return;

    this.saveUserInfoToLocalStorage();
  }

  public initBySuccessUpdate(dto: any) {
    this.lastName = dto.lastName;
    this.firstName = dto.firstName;
    this.secondName = dto.secondName;

    this.saveUserInfoToLocalStorage();
  }

  private setEditableInitialInfo(firstName: string, secondName: string, lastName: string, companyName: string) {
    this.initialFirstName = firstName;
    this.initialSecondName = secondName;
    this.initialLastName = lastName;
  }

  private checkInfoUpdate(): boolean {
    return (
      this.initialLastName !== this.lastName ||
      this.initialFirstName !== this.firstName ||
      this.initialSecondName !== this.secondName
    );
  }

  public saveUserInfoToLocalStorage() {
    const localStorageUserData: IUserInfoGetDto = {
      registrationDate: this.registrationDate,
      lastName: this.lastName,
      firstName: this.firstName,
      secondName: this.secondName,
      email: this.email,
      roles: this.roles,
      token: this.token,
    };

    LocalStorageService.putDataToLocalStorage<IUserInfoGetDto>(USER_INFO_STORAGE_KEY, localStorageUserData);
  }

  public getUserInfoFromLocalStorage() {
    const userData: IUserInfoGetDto | null = LocalStorageService.getDataFromLocalStorageByKey(USER_INFO_STORAGE_KEY);
    if (!userData) return;

    this.initBySuccessAuth(userData, true);
  }

  public deInit() {
    this.registrationDate = '';
    this.lastName = '';
    this.firstName = '';
    this.secondName = '';
    this.email = '';

    this.roles = [];
    this.token = '';
    this.refreshToken = '';

    this.deInitPassAndValidation();
  }

  public deInitPassAndValidation() {
    this.password = '';
    this.repeatedPassword = '';

    this.validation.deInit();
  }
}

export default UserInfoModel;
