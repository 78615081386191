import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { FormRow, IconBtn, Spinner, TextInput } from 'components';

import { authUserStore } from 'stores';

import { TextL } from 'components/TextWrappers';

import c from './userInfoForm.module.scss';

const UserInfoForm = () => {
  useEffect(() => {
    authUserStore.userInfoModel.getUserInfoFromLocalStorage();

    return () => authUserStore.userInfoModel.deInit();
  }, []);

  return (
    <div className={c.regFormWrapper}>
      <TextL className={c.formTitle} text={'Личный кабинет'} />

      <div className={c.formContent}>
        <Spinner isLoading={authUserStore.isPending} />

        <FormRow
          label={'Фамилия'}
          validationMessage={authUserStore.userInfoModel.validation.checkRequirements[0].currentNotice}
          input={
            <TextInput
              value={authUserStore.userInfoModel.lastName}
              validStatus={authUserStore.userInfoModel.validation.checkRequirements[0].validStatus}
              onChange={authUserStore.userInfoModel.onChangeLastName}
            />
          }
        />

        <FormRow
          label={'Имя'}
          validationMessage={authUserStore.userInfoModel.validation.checkRequirements[1].currentNotice}
          input={
            <TextInput
              value={authUserStore.userInfoModel.firstName}
              validStatus={authUserStore.userInfoModel.validation.checkRequirements[1].validStatus}
              onChange={authUserStore.userInfoModel.onChangeFirstName}
            />
          }
        />

        <FormRow
          label={'Отчество'}
          validationMessage={authUserStore.userInfoModel.validation.checkRequirements[2].currentNotice}
          input={
            <TextInput
              value={authUserStore.userInfoModel.secondName}
              validStatus={authUserStore.userInfoModel.validation.checkRequirements[2].validStatus}
              onChange={authUserStore.userInfoModel.onChangeSecondName}
            />
          }
        />

        <FormRow
          label={'Email'}
          validationMessage={authUserStore.userInfoModel.validation.checkRequirements[3].currentNotice}
          input={
            <TextInput
              disabled={true}
              value={authUserStore.userInfoModel.email}
              validStatus={authUserStore.userInfoModel.validation.checkRequirements[3].validStatus}
              onChange={authUserStore.userInfoModel.onChangeEmail}
            />
          }
        />

        <FormRow
          label={'Пароль'}
          validationMessage={authUserStore.userInfoModel.validation.checkRequirements[4].currentNotice}
          input={
            <TextInput
              value={authUserStore.userInfoModel.password}
              validStatus={authUserStore.userInfoModel.validation.checkRequirements[4].validStatus}
              onChange={authUserStore.userInfoModel.onTypePassword}
              password={!authUserStore.userInfoModel.isShowPassword}
              endAdornment={
                <IconBtn
                  className={c.showPswBtn}
                  icon={authUserStore.userInfoModel.isShowPassword ? <Visibility /> : <VisibilityOff />}
                  onMouseDown={authUserStore.userInfoModel.showPassword}
                  onMouseUp={authUserStore.userInfoModel.hidePassword}
                  onMouseLeave={authUserStore.userInfoModel.hidePassword}
                />
              }
            />
          }
        />

        <FormRow
          label={'Повторить пароль'}
          validationMessage={authUserStore.userInfoModel.validation.checkRequirements[5].currentNotice}
          input={
            <TextInput
              value={authUserStore.userInfoModel.repeatedPassword}
              validStatus={authUserStore.userInfoModel.validation.checkRequirements[5].validStatus}
              onChange={authUserStore.userInfoModel.onTypeRepeatedPassword}
              password={!authUserStore.userInfoModel.isShowRepeatedPassword}
              endAdornment={
                <IconBtn
                  className={c.showPswBtn}
                  icon={authUserStore.userInfoModel.isShowRepeatedPassword ? <Visibility /> : <VisibilityOff />}
                  onMouseDown={authUserStore.userInfoModel.showRepeatedPassword}
                  onMouseUp={authUserStore.userInfoModel.hideRepeatedPassword}
                  onMouseLeave={authUserStore.userInfoModel.hideRepeatedPassword}
                />
              }
            />
          }
        />
      </div>
    </div>
  );
};

export default observer(UserInfoForm);
