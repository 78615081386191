import { makeAutoObservable } from 'mobx';

import { appStore } from './index';

import theatreApiService from '../apiServices/theatreApiService';

import { SnackBarStates } from 'shared/enums';
import { TheatreInfoModel } from '../shared/models/theatreInfo/TheatreInfoModel';
import { IFileDto } from '../shared/interfaces/api/files/IFileDto';
import { fileApiService } from '../apiServices';

class TheatreStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public history = '';

  public isShowForm = false;

  public theatreInfo = new TheatreInfoModel();

  public theatreInfoForm = new TheatreInfoModel();

  public openForm() {
    this.isShowForm = true;
    this.getTheatreInfo();
  }

  public closeForm() {
    this.isShowForm = false;
    this.theatreInfoForm.deInit();
  }

  public async getTheatreInfo() {
    try {
      this.isPending = true;

      const dto = await theatreApiService.getTheatreInfo();
      this.theatreInfoForm.init(dto);
      this.theatreInfo.init(dto);
    } catch (e: any) {
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при получении информации');
    } finally {
      this.isPending = false;
    }
  }

  private async postFile(): Promise<IFileDto | void> {
    try {
      this.isPending = true;
      const formData = new FormData();
      formData.append('file', this.theatreInfoForm.imageFile);
      const result = await fileApiService.postFile(formData);
      return result;
    } catch (e) {
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public async putTheatreInfo() {
    try {
      this.isPending = true;

      const result = await this.postFile();

      if (result) {
        this.theatreInfoForm.setImageId(result.id);
      }

      const putInfo = this.theatreInfoForm.getPutDto();
      await theatreApiService.putTheatreInfo(putInfo);
      this.closeForm();
      appStore.showSnackbar(SnackBarStates.Success, 'Информация успешно обновлена');
    } catch (e: any) {
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при изменении информации');
    } finally {
      this.isPending = false;
    }

    this.getTheatreInfo();
  }
}

export default new TheatreStore();
