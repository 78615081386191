import { makeAutoObservable } from 'mobx';

import { userApiService } from 'apiServices';

import { appStore } from './index';

import { UserRowModel, UsersRequestParamsControl } from 'shared/models/users';

import { SnackBarStates, TabNames, UserType } from 'shared/enums';

import { IUsersStoreDI } from 'shared/interfaces/app/di';
import { Debounce } from '../shared/models/serve';

class UsersStore implements IUsersStoreDI {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public debounce = new Debounce();

  public isPending = false;

  private startRecordNumber = 0;

  public totalDataLength = 0;

  public userIdsToRemove: string[] = [];

  public userIdToRestore = '';

  public userGroupSelected: UserType = UserType.Admin;

  public users: UserRowModel[] = [];

  public requestParamsControl = new UsersRequestParamsControl(this, 15, this.users.length);

  public async setStartRecordNumber(startFrom: number, preventRequest?: boolean) {
    this.startRecordNumber = startFrom;

    if (startFrom === 0) {
      this.users = [];
    }

    if (preventRequest) return;

    const isTimeToGetRequest = await this.debounce.getTerm(
      this.requestParamsControl.filtersControl.hasAnyAppliedFilters ? 500 : 0
    );

    if (!isTimeToGetRequest) return;

    await this.getUsersAccounts();
  }

  public get userNameToRemove() {
    if (this.userIdsToRemove.length > 1) return '-';
    const user = this.users.find((item) => item.id === this.userIdsToRemove[0]);
    if (!user) return '-';

    return this.userGroupSelected === UserType.Admin
      ? `${user.lastName || ''} ${user.firstName} ${user.secondName}`
      : user.email;
  }

  public get userNameToRestore() {
    const user = this.users.find((item) => item.id === this.userIdToRestore);
    if (!user) return '-';

    return `${user.lastName} ${user.firstName} ${user.secondName}`;
  }

  public onSelectUserGroup(userType: UserType) {
    this.userGroupSelected = userType;
    this.requestParamsControl.filtersControl.setUserType(userType);

    this.debounce.deInitDebounce();
    this.setStartRecordNumber(0);
  }

  public showRemoveDialog(userId: string) {
    this.userIdsToRemove = [userId];
  }

  public closeRemoveDialog() {
    this.userIdsToRemove = [];
  }

  public showRestoreDialog(userId: string) {
    this.userIdToRestore = userId;
  }

  public closeRestoreDialog() {
    this.userIdToRestore = '';
  }

  public removeUsers() {
    this.userIdsToRemove = this.users.filter((user) => user.isSelected).map((user) => user.id);
  }

  public async removeUser() {
    try {
      this.isPending = true;

      const result = userApiService.removeUser(this.userIdsToRemove);
      if (!result) return;

      const removedUsers = this.users.filter((item) => this.userIdsToRemove.includes(item.id));
      removedUsers.forEach((item) => item.setAsRemoved());

      this.closeRemoveDialog();

      appStore.showSnackbar(SnackBarStates.Success, 'Удаление выполнено успешно');
    } catch (e) {
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public async restoreUser() {
    try {
      this.isPending = true;

      const isRestored = await userApiService.restoreUser(this.userIdToRestore);
      const user = this.users.find((item) => item.id === this.userIdToRestore);

      if (isRestored && user) {
        user.setAsRestored();
        this.closeRestoreDialog();
        appStore.showSnackbar(SnackBarStates.Success, 'Пользователь успешно восстановлен');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public async confirmUser(userId: string) {
    try {
      this.isPending = true;

      const isConfirmed = await userApiService.confirmUser(userId);
      const user = this.users.find((item) => item.id === userId);

      if (isConfirmed && user) {
        user.setAsConfirmed();
        appStore.showSnackbar(SnackBarStates.Success, 'Пользователь успешно подтверждён');
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public getNextPage() {
    this.startRecordNumber = this.users.length;
    this.getUsersAccounts();
  }

  public async getUsersAccounts() {
    try {
      this.isPending = true;

      const queryParams = this.requestParamsControl.getRequestParams();

      const usersAccountDto = await userApiService.getUsersAccounts(queryParams);
      if (!usersAccountDto) return;

      this.totalDataLength = usersAccountDto.totalCount;

      const newPageData = usersAccountDto.models.map(
        (item, index) => new UserRowModel(this.users.length + index + 1, item)
      );

      this.users.push(...newPageData);
    } catch (e) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при получении списка пользователей');
    } finally {
      this.isPending = false;
    }
  }

  public deInit(preventRequest?: boolean) {
    this.isPending = false;
    this.requestParamsControl.deInit(TabNames.Users, preventRequest);
    this.users = [];
    this.startRecordNumber = 0;
    this.userIdsToRemove = [];

    this.debounce.deInitDebounce();
  }
}

export default new UsersStore();
