import dayjs from 'dayjs';
import { DateTimePicker as Picker } from '@mui/x-date-pickers';

import c from './datePicker.module.scss';

interface IProps {
  date: string;
  disabled?: boolean;
  onChangeDate(date: Date): void;
}

const DatePicker = (props: IProps) => {
  const { date, disabled, onChangeDate } = props;

  const onSetDate = (a: any) => {
    try {
      const selectedDateStr = a.$d;

      onChangeDate(selectedDateStr);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={c.datePickerWrapper}>
      <Picker
        disabled={disabled}
        onChange={onSetDate}
        value={dayjs(date)}
        views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
      />
    </div>
  );
};

export default DatePicker;
