import React from 'react';

import { Button } from '@mui/material';

import { UIElemSizes } from 'shared/enums';

import { TextButton } from '../../TextWrappers';

import c from './secondaryButton.module.scss';

interface IProps {
  caption?: string;
  onClick(event?: React.MouseEvent<HTMLButtonElement>): void;

  disabled?: boolean;
  size?: UIElemSizes;

  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

const SecondaryButton = (props: IProps) => {
  const { onClick, disabled, caption, startIcon, endIcon } = props;

  return (
    <Button
      className={c.secondaryButton}
      variant="outlined"
      disableRipple={true}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}>
      <TextButton text={caption} />
    </Button>
  );
};

export default SecondaryButton;
