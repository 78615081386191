import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Delete, RestoreFromTrash } from '@mui/icons-material';
import { SelectedGroup, TabNames, UIElemSizes, UserType } from 'shared/enums';

import {
  CheckBox,
  DatePicker,
  IconBtn,
  Modal,
  Select,
  Spinner,
  TabHeadLine,
  TextInput,
  UserTypesSelector,
  VirtualTable,
} from 'components';
import { TextM } from 'components/TextWrappers';

import { authUserStore, usersStore } from 'stores';

import { IVirtualTableColumn } from 'shared/interfaces/app/IVirtualTableColumn';

import { TooltipPlacement } from 'components/Buttons/IconButton/IconBtn';

import { UserRowModel } from 'shared/models/users';

import c from './users.module.scss';

const Users = () => {
  useEffect(() => {
    usersStore.debounce.deInitDebounce();
    usersStore.requestParamsControl.initFromURL(TabNames.Users);
    usersStore.getUsersAccounts();

    return () => usersStore.deInit(true);
  }, []);

  const columns: IVirtualTableColumn[] = [
    {
      dataField: 'lastName',
      columnWidth: '15%',
      name: 'Фамилия',
      sortControl: usersStore.requestParamsControl.sortControl,
      filtered: !!usersStore.requestParamsControl.filtersControl.lastNamePattern,
      onRemoveFilter: () => usersStore.requestParamsControl.filtersControl.onChangeLastNamePattern(''),
      filterControl: () => {
        return (
          <div className={c.columnFilterWrapper}>
            <TextInput
              value={usersStore.requestParamsControl.filtersControl.lastNamePattern}
              onChange={usersStore.requestParamsControl.filtersControl.onChangeLastNamePattern}
            />
          </div>
        );
      },
    },
    {
      dataField: 'firstName',
      columnWidth: '15%',
      name: 'Имя',
      sortControl: usersStore.requestParamsControl.sortControl,
      filtered: !!usersStore.requestParamsControl.filtersControl.firstNamePattern,

      onRemoveFilter: () => usersStore.requestParamsControl.filtersControl.onChangeFirstNamePattern(''),
      filterControl: () => {
        return (
          <div className={c.columnFilterWrapper}>
            <TextInput
              value={usersStore.requestParamsControl.filtersControl.firstNamePattern}
              onChange={usersStore.requestParamsControl.filtersControl.onChangeFirstNamePattern}
            />
          </div>
        );
      },
    },
    {
      dataField: 'secondName',
      columnWidth: '15%',
      name: 'Отчество',
      filtered: !!usersStore.requestParamsControl.filtersControl.secondNamePattern,
      sortControl: usersStore.requestParamsControl.sortControl,
      onRemoveFilter: () => usersStore.requestParamsControl.filtersControl.onChangeSecondNamePattern(''),
      filterControl: () => {
        return (
          <div className={c.columnFilterWrapper}>
            <TextInput
              value={usersStore.requestParamsControl.filtersControl.secondNamePattern}
              onChange={usersStore.requestParamsControl.filtersControl.onChangeSecondNamePattern}
            />
          </div>
        );
      },
    },
    {
      dataField: 'email',
      columnWidth: usersStore.userGroupSelected === UserType.Spectator ? '50%' : '15%',
      name: 'Email',
      filtered: !!usersStore.requestParamsControl.filtersControl.eMailPattern,
      sortControl: usersStore.requestParamsControl.sortControl,
      onRemoveFilter: () => usersStore.requestParamsControl.filtersControl.onChangeEmailPattern(''),
      filterControl: () => {
        return (
          <div className={c.columnFilterWrapper}>
            <TextInput
              value={usersStore.requestParamsControl.filtersControl.eMailPattern}
              onChange={usersStore.requestParamsControl.filtersControl.onChangeEmailPattern}
            />
          </div>
        );
      },
    },
    {
      dataField: 'registrationDate',
      columnWidth: '20%',
      name: 'Дата регистрации',
      sortControl: usersStore.requestParamsControl.sortControl,
      filtered:
        !!usersStore.requestParamsControl.filtersControl.registrationDateFrom ||
        !!usersStore.requestParamsControl.filtersControl.registrationDateTo,
      onRemoveFilter: () => usersStore.requestParamsControl.filtersControl.dropDatesFilter(),
      filterControl: () => {
        return (
          <div className={c.columnDateFilterWrapper}>
            <DatePicker
              date={usersStore.requestParamsControl.filtersControl.registrationDateFrom}
              onChangeDate={usersStore.requestParamsControl.filtersControl.onSelectRegistrationFrom}
            />
            <DatePicker
              date={usersStore.requestParamsControl.filtersControl.registrationDateTo}
              onChangeDate={usersStore.requestParamsControl.filtersControl.onSelectRegistrationTo}
            />
          </div>
        );
      },
    },
    {
      dataField: 'isConfirmed',
      columnWidth: '15%',
      name: 'Подтверждённая учётная запись',
      filtered: usersStore.requestParamsControl.filtersControl.confirmedState !== SelectedGroup.All,
      sortControl: usersStore.requestParamsControl.sortControl,
      onRemoveFilter: () => usersStore.requestParamsControl.filtersControl.onSelectConfirmed(SelectedGroup.All),
      filterControl: () => {
        return (
          <div className={c.columnSelectFilterWrapper}>
            <Select
              items={[
                { id: SelectedGroup.All, name: 'Все' },
                { id: SelectedGroup.SelectedOnly, name: 'Только подтверждённые' },
                { id: SelectedGroup.UnselectedOnly, name: 'Только неподтверждённые' },
              ]}
              onChange={usersStore.requestParamsControl.filtersControl.onSelectConfirmed}
              value={usersStore.requestParamsControl.filtersControl.confirmedState}
            />
          </div>
        );
      },
      renderCustomCell: (row: UserRowModel) => {
        const onConfirmUser = () => {
          usersStore.confirmUser(row.id);
        };

        return (
          <div className={c.checkBoxWrapper}>
            <CheckBox
              disabled={row.isConfirmed || row.isDeleted || row.email === authUserStore.userInfoModel.email}
              checked={row.isConfirmed}
              onChange={onConfirmUser}
            />
          </div>
        );
      },
    },
    {
      dataField: 'isDeleted',
      columnWidth: '15%',
      name: 'Удалён',
      filtered: usersStore.requestParamsControl.filtersControl.deletedState !== SelectedGroup.All,
      sortControl: usersStore.requestParamsControl.sortControl,
      onRemoveFilter: () => usersStore.requestParamsControl.filtersControl.onSelectDeleted(SelectedGroup.All),
      filterControl: () => {
        return (
          <div className={c.columnSelectFilterWrapper}>
            <Select
              items={[
                { id: SelectedGroup.All, name: 'Все' },
                { id: SelectedGroup.SelectedOnly, name: 'Только удалённые' },
                { id: SelectedGroup.UnselectedOnly, name: 'Только не удалённые' },
              ]}
              onChange={usersStore.requestParamsControl.filtersControl.onSelectDeleted}
              value={usersStore.requestParamsControl.filtersControl.deletedState}
            />
          </div>
        );
      },
      renderCustomCell: (row: UserRowModel) => {
        const onRemove = () => {
          usersStore.showRemoveDialog(row.id);
        };

        const onRestore = () => {
          usersStore.showRestoreDialog(row.id);
        };

        return (
          <div className={c.checkBoxWrapper}>
            {row.isDeleted ? (
              <IconBtn
                disabled={row.email === authUserStore.userInfoModel.email}
                className={c.btn}
                icon={<RestoreFromTrash />}
                hint={'Восстановить пользователя'}
                hintPlacement={TooltipPlacement.Top}
                onClick={onRestore}
              />
            ) : (
              <IconBtn
                disabled={row.email === authUserStore.userInfoModel.email}
                className={c.btn}
                hintPlacement={TooltipPlacement.Top}
                icon={<Delete />}
                hint={'Удалить пользователя'}
                onClick={onRemove}
              />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className={c.usersWrapper}>
      <Spinner isLoading={usersStore.isPending} size={UIElemSizes.Lg} />
      <TabHeadLine
        freeContent={
          <UserTypesSelector onSelect={usersStore.onSelectUserGroup} selected={usersStore.userGroupSelected} />
        }
        name={TabNames.Users}
        isPending={usersStore.isPending}
      />

      <div className={c.contentWrapper}>
        <VirtualTable
          columns={
            usersStore.userGroupSelected === UserType.Spectator
              ? columns.filter(
                  (item) =>
                    item.dataField !== 'isConfirmed' &&
                    item.dataField !== 'lastName' &&
                    item.dataField !== 'secondName' &&
                    item.dataField !== 'firstName'
                )
              : columns
          }
          data={usersStore.users}
          getNextPage={usersStore.getNextPage}
          hasAnyAppliedFilters={usersStore.requestParamsControl.filtersControl.hasAnyAppliedFilters}
          totalDataLength={usersStore.totalDataLength}
          onRemoveAllFilters={usersStore.requestParamsControl.filtersControl.deInit}
        />
      </div>

      <Modal
        confirmCaption={'Удалить'}
        cancelCaption={'Отмена'}
        onClose={usersStore.closeRemoveDialog}
        onConfirm={usersStore.removeUser}
        isOpened={usersStore.userIdsToRemove.length > 0}>
        <TextM className={c.confirmDialog} text={`Удалить пользователя ${usersStore.userNameToRemove}?`} />
      </Modal>

      <Modal
        confirmCaption={'Восстановить'}
        cancelCaption={'Отмена'}
        onClose={usersStore.closeRestoreDialog}
        onConfirm={usersStore.restoreUser}
        isOpened={!!usersStore.userIdToRestore}>
        <TextM className={c.confirmDialog} text={`Восстановить пользователя ${usersStore.userNameToRestore}?`} />
      </Modal>

      <div className={c.bottomSpace}></div>
    </div>
  );
};

export default observer(Users);
