import BaseApiService from './BaseApiService';

import { IPerformanceResponseDto, IPerformanceRowDto } from 'shared/interfaces/api/performacnes/IPerfomanceGetDto';
import { IPerformancePostDto } from '../shared/interfaces/api/performacnes/IPerformancePostDto';
import { IFileDto } from '../shared/interfaces/api/files/IFileDto';

class FileApiService extends BaseApiService {
  constructor() {
    super('api/File');
  }

  public postFile(payLoad: FormData): Promise<IFileDto> {
    const options: any = { headers: {} };

    options.headers['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundarywugGhLqVxsUnWAa0';

    return this.POST(``, payLoad, options);
  }

  public getFileBlobById(fileId: string) {
    const options: any = { headers: {} };
    options.headers['Content-Type'] = 'application/octet-stream ';
    return this.GET(`22d33267-8928-45a1-a4bc-bada0ef7b3d6`, options);
  }
}

export default new FileApiService();
