import React, { useState } from 'react';
import { observer } from 'mobx-react';

import { ArrowBack, ArrowDownward, ArrowForward, ArrowUpward, Close, FilterAlt } from '@mui/icons-material';

import { IconBtn } from 'components/Buttons';
import TextThCell from 'components/TextWrappers/TextThCell';

import SortControl, { SortDirection } from 'shared/models/serve/SortControl';

import { IVirtualTableColumn } from 'shared/interfaces/app/IVirtualTableColumn';

import c from './header.module.scss';

interface IProps {
  id: string;
  hasAnyAppliedFilters: boolean;
  columns: IVirtualTableColumn[];
  onRemoveAllFilters(): void;
}

const Header = ({ id, hasAnyAppliedFilters, columns, onRemoveAllFilters }: IProps) => {
  const [isShowFilter, setShowFilterState] = useState(true);

  const onFilterStateClick = () => {
    setShowFilterState(!isShowFilter);
  };

  const getSortIcon = (columnName: string, sortControl: SortControl<any>) => {
    if (sortControl.columnName.toLowerCase() !== columnName.toLowerCase()) {
      return <ArrowForward className={c.invisibleSortBtn} />;
    }

    if (
      sortControl.columnName.toLowerCase() === columnName.toLowerCase() &&
      sortControl.direction === SortDirection.Undirected
    ) {
      return <ArrowForward />;
    }

    if (
      sortControl.columnName.toLowerCase() === columnName.toLowerCase() &&
      sortControl.direction === SortDirection.Asc
    ) {
      return <ArrowUpward />;
    }

    if (
      sortControl.columnName.toLowerCase() === columnName.toLowerCase() &&
      sortControl.direction === SortDirection.Desc
    ) {
      return <ArrowDownward />;
    }

    return null;
  };

  const onRemoveAll = () => {
    onRemoveAllFilters();
  };

  return (
    <div className={c.headerWrapper} id={id}>
      <div className={c.filtersControlWrapper}>
        <FilterAlt
          className={
            hasAnyAppliedFilters ? `${c.totalFilterState} ${c.applied}` : `${c.totalFilterState} ${c.notApplied}`
          }
        />

        {hasAnyAppliedFilters && <IconBtn className={c.clearAllBtn} icon={<Close />} onClick={onRemoveAll} />}

        <IconBtn
          onClick={onFilterStateClick}
          className={c.filterStateControl}
          icon={isShowFilter ? <ArrowForward /> : <ArrowBack />}
        />
      </div>

      <>
        {columns.map((column) => {
          const onSortClick = () => {
            column.sortControl?.onSortClick(column.dataField);
          };

          let columnStatClasses = c.nameAndSortDirection;

          if (!!column.filterControl) {
            columnStatClasses = `${c.nameAndSortDirection} ${c.nameWrapperShortSpace}`;
          }

          if (column.sortControl) {
            columnStatClasses += ` ${c.hovered}`;
          }

          return (
            <div
              className={c.headerCellWrapper}
              key={column.dataField}
              style={{
                width: column.columnWidth || '100%',
                maxWidth: column.columnWidth,
                minWidth: column.minWidth,
              }}>
              <div className={c.columnCellWrapper}>
                <div className={c.columnState}>
                  <div className={columnStatClasses}>
                    {column.sortControl && (
                      <IconBtn
                        onClick={onSortClick}
                        className={c.sortBtn}
                        icon={getSortIcon(column.dataField, column.sortControl)}
                      />
                    )}
                    <TextThCell noWrap={false} text={column.name} onClick={onSortClick} />
                  </div>
                  {!!column.filterControl && isShowFilter && (
                    <FilterAlt
                      className={column.filtered ? `${c.filterState} ${c.applied}` : `${c.filterState} ${c.notApplied}`}
                    />
                  )}
                </div>
                {!!column.freeContent ? column.freeContent() : null}
                {column.filterControl && isShowFilter && column.filterControl()}
                {column.filtered && isShowFilter && (
                  <IconBtn className={c.removeBtn} onClick={column.onRemoveFilter} icon={<Close />} />
                )}
              </div>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default observer(Header);
