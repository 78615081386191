import { TextM } from '../TextWrappers';

import { ISelectItem } from 'shared/interfaces/app';

import c from './topTabs.module.scss';

interface IProps {
  className?: string;
  tabItems: ISelectItem[];
  onClick(tabName: string): void;
  selectedTabId: string;
}

const TopTabs = (props: IProps) => {
  const { className, tabItems, selectedTabId, onClick } = props;

  const mainTabClasses = `${c.tabItem} unselectable`;

  return (
    <div className={className ? `${c.tabWrapper} ${className}` : c.tabWrapper}>
      {tabItems.map((tab) => {
        const onClickTab = () => {
          onClick(tab.id);
        };

        const onKeyDown = (e: React.KeyboardEvent) => {
          if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === 'Space') {
            onClick(tab.id);
          }
        };

        return (
          <div
            key={tab.id}
            onKeyDown={onKeyDown}
            tabIndex={0}
            className={selectedTabId === tab.id ? `${c.selected} ${mainTabClasses}` : mainTabClasses}
            onClick={onClickTab}>
            <TextM text={tab.name} />
          </div>
        );
      })}
    </div>
  );
};

export default TopTabs;
