import { observer } from 'mobx-react';

import { performanceStore } from 'stores';

import { TextL, TextM, TextS } from 'components/TextWrappers';
import { ColorsVars, TextAlign } from 'shared/enums';
import { performancesApiService } from 'apiServices';

import c from './preveiew.module.scss';

const Preview = () => {
  return (
    <div className={c.previewWrapper}>
      <div className={c.previewMobile} style={{ backgroundColor: performanceStore.performance.backgroundColor }}>
        <div className={c.contentWrapper}>
          <div className={c.top}>
            {performanceStore.performance.previewLogoImage || performanceStore.performance.logoId ? (
              <img
                className={c.performanceLogo}
                src={
                  performanceStore.performance.previewLogoImage
                    ? performanceStore.performance.previewLogoImage
                    : `${performancesApiService.host}/images/${performanceStore.performance.logoId}`
                }
              />
            ) : (
              <TextS
                className={
                  performanceStore.performance.previewLogoImage
                    ? c.performanceLogo
                    : `${c.performanceLogo} ${c.bordered}`
                }
                color={ColorsVars.TypoWhite}
                text={'Место для логотипа. Размер изображения 215px x 215px. Формат PNG'}
              />
            )}

            <TextL
              className={c.performanceName}
              hexColor={performanceStore.performance.textColor}
              text={performanceStore.performance.name}
            />
          </div>

          <div className={c.body}>
            <div className={c.infoRow}>
              <TextS className={c.mainInfoTitle} color={ColorsVars.TypoWhite} text={'Основано на произведении'} />
              <TextM
                className={c.mainInfoValue}
                color={ColorsVars.TypoWhite}
                text={performanceStore.performance.inspiredBy}
              />
            </div>

            <div className={c.infoRow}>
              <TextS className={c.mainInfoTitle} color={ColorsVars.TypoWhite} text={'Автор'} />
              <TextM
                className={c.mainInfoValue}
                color={ColorsVars.TypoWhite}
                text={performanceStore.performance.author}
              />
            </div>

            <div className={c.infoRow}>
              <TextS className={c.mainInfoTitle} color={ColorsVars.TypoWhite} text={'Продолжительность'} />
              <TextM
                className={c.mainInfoValue}
                color={ColorsVars.TypoWhite}
                text={performanceStore.performance.durationFormatted}
              />
            </div>

            <div className={c.infoRow}>
              <TextS className={c.mainInfoTitle} color={ColorsVars.TypoWhite} text={'Расстояние маршрута'} />

              <TextM
                className={c.mainInfoValue}
                color={ColorsVars.TypoWhite}
                text={performanceStore.performance.distanceFormatted}
              />
            </div>

            <div className={c.infoRow}>
              <TextS className={c.mainInfoTitle} color={ColorsVars.TypoWhite} text={'Рекомендуемый возраст'} />
              <TextM
                className={c.mainInfoValue}
                color={ColorsVars.TypoWhite}
                text={performanceStore.performance.minAgeFormatted}
              />
            </div>

            <div className={c.infoRow}>
              <TextS className={c.mainInfoTitle} color={ColorsVars.TypoWhite} text={'Размер файла спектакля'} />
              <TextM
                className={c.mainInfoValue}
                color={ColorsVars.TypoWhite}
                text={performanceStore.performance.arFileSizeFormatted}
              />
            </div>

            <div className={c.infoRow}>
              <TextS className={c.storyTitle} color={ColorsVars.TypoWhite} text={'Сюжет'} />

              <TextS
                className={c.storyDesc}
                color={ColorsVars.TypoWhite}
                text={performanceStore.performance.description?.toString()}
              />
            </div>

            <div className={c.infoRow}>
              {performanceStore.performance.previewMapImage || performanceStore.performance.mapPreviewId ? (
                <img
                  className={c.mapShotWrapper}
                  src={
                    performanceStore.performance.previewMapImage
                      ? performanceStore.performance.previewMapImage
                      : `${performancesApiService.host}/images/${performanceStore.performance.mapPreviewId}`
                  }
                />
              ) : (
                <TextS
                  className={
                    performanceStore.performance.previewMapImage
                      ? c.mapShotWrapper
                      : `${c.mapShotWrapper} ${c.bordered}`
                  }
                  color={ColorsVars.TypoWhite}
                  text={'Место для изображения карты. Размер изображения 327px x 491px. Формат PNG '}
                />
              )}
            </div>

            <div className={c.routeDesc}>
              <TextS className={c.descTitle} color={ColorsVars.TypoWhite} text={'Описание маршрута'} />

              <div>
                {performanceStore.performance.checkpoints.map((item) => (
                  <div className={c.pointWrapper} key={item.number}>
                    <div className={c.pointLeftWrapper}>
                      <div
                        className={c.numberCircleWrapper}
                        style={{ color: performanceStore.performance.backgroundColor }}>
                        {item.number}
                      </div>
                      <div className={c.stick} />
                    </div>

                    <div className={c.pointRightWrapper}>
                      <TextS className={c.pointName} textAlign={TextAlign.Start} text={item.name} hexColor={'#fff'} />
                      <TextS
                        className={c.pointAddress}
                        textAlign={TextAlign.Start}
                        text={item.address}
                        hexColor={'#fff'}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Preview);
