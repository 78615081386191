import { makeAutoObservable } from 'mobx';

import { CheckPointModel } from './CheckPointModel';
import { PerformanceTab } from '../../enums';
import { Utils } from '../../../services/utils';

import { IPerformancePostDto } from '../../interfaces/api/performacnes/IPerformancePostDto';
import { ICheckpointInfo, IPerformanceRowDto } from '../../interfaces/api/performacnes';
import { IPerformancePutDto } from '../../interfaces/api/performacnes/IPerformancePutDto';
import { ISelectItem } from '../../interfaces/app';
import { ISeasonGetRowDto } from '../../interfaces/seasons/ISeasonsResponseDto';

export class PerformanceModel {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.calculateDistance();
  }

  public id = '';

  public logoId = '';

  public mapPreviewId = '';

  public name = 'Название спектакля';

  public inspiredBy = 'По мотивам пьесы...';

  public author = 'Автор';

  public description = 'В период драматической эпохи...';

  public shortDescription = '';

  public duration: number | null = 60;

  public distance = 0;

  public minAge: number | null = 12;

  public backgroundColor = '#1E4A48';

  public textColor = '#FEFEFE';

  public isComingSoon = false;

  public isArchival = false;

  public screenURL = '';

  public mapPreviewWasUpdate = false;

  public arFileSize: number | null = 0;

  public selectedSeasonId = '';

  public checkpoints: CheckPointModel[] = [new CheckPointModel(1, this)];

  private deleteCheckpoints: string[] = [];

  public seasonsList: ISelectItem[] = [];

  public selectedTab: PerformanceTab = PerformanceTab.CommonInfo;

  public eventIds = '';

  public bounds: number[][] = [
    [56.47750562101612, 84.94094556607472],
    [56.48916474391545, 84.95497888364063],
  ];

  public get arFileSizeFormatted() {
    return `${this.arFileSize} Мб`;
  }

  public get durationFormatted() {
    return `${this.duration} мин`;
  }

  public get minAgeFormatted() {
    return `${this.minAge} +`;
  }

  public onChangeEventIds(eventIds: string) {
    this.eventIds = eventIds;
  }

  public setSeasons(seasons: ISeasonGetRowDto[]) {
    this.seasonsList = seasons.map((item) => ({ id: item.id, name: item.name }));

    if (this.seasonsList.length > 0) {
      this.selectedSeasonId = this.seasonsList[0].id;
    }
  }

  public selectSeason(id: string) {
    this.selectedSeasonId = id;
  }

  public setBounds(bb: number[][]) {
    this.bounds = bb;
  }

  public initFromDto(dto: IPerformanceRowDto) {
    this.id = dto.id;
    if (dto.logo) {
      this.logoId = dto.logo.id;
    }

    if (dto.mapData.mapTexture) {
      this.mapPreviewId = dto.mapData.mapTexture.id;
    }

    this.name = dto.name;
    this.inspiredBy = dto.inspiredBy;
    this.author = dto.author;
    this.shortDescription = dto.shortDescription;
    this.description = dto.description;
    this.duration = dto.duration;
    this.minAge = dto.minAge;
    this.selectedSeasonId = dto.season.id;
    this.backgroundColor = dto.backgroundColor;
    this.textColor = dto.textColor;
    this.isComingSoon = dto.isComingSoon;
    this.eventIds = dto.idEvents;
    this.isArchival = dto.isArchival;
    this.arFileSize = parseInt(dto.arFileSize);

    this.bounds[0][0] = dto.mapData.bottomLeftCoord.latitude;
    this.bounds[0][1] = dto.mapData.bottomLeftCoord.longitude;
    this.bounds[1][0] = dto.mapData.topRightCoord.latitude;
    this.bounds[1][1] = dto.mapData.topRightCoord.longitude;

    if (dto.checkpoints && dto.checkpoints.length > 1) {
      this.checkpoints = dto.checkpoints
        .sort((a: ICheckpointInfo, b: ICheckpointInfo) => (a.index > b.index ? 1 : -1))
        .map((item) => {
          const checkpoint = new CheckPointModel(item.index, this);
          checkpoint.initByDto(item);
          return checkpoint;
        });
    }

    if (dto.checkpoints && dto.checkpoints.length < 2) {
      this.checkpoints = dto.checkpoints.map((item) => {
        const checkpoint = new CheckPointModel(item.index, this);
        checkpoint.initByDto(item);
        return checkpoint;
      });
    }

    this.calculateDistance();
  }

  public calculateDistance() {
    let totalDistance = 0;

    for (let i = 0; i < this.checkpoints.length - 1; i++) {
      const distance = Utils.getDistance(
        this.checkpoints[i].lat,
        this.checkpoints[i].lng,
        this.checkpoints[i + 1].lat,
        this.checkpoints[i + 1].lng
      );

      totalDistance += distance;
    }

    this.distance = Math.round((totalDistance / 1000) * 100) / 100;
  }

  public setLogoId(id: string) {
    this.logoId = id;
  }

  public setMapPreviewId(id: string) {
    this.mapPreviewId = id;
  }

  public get distanceFormatted() {
    return `${this.distance} км`;
  }

  public get polyline() {
    const points = this.checkpoints.map((item) => [item.lat, item.lng]);

    return points;
  }

  public getPostDto(): IPerformancePostDto {
    this.mapPreviewWasUpdate = false;

    const postDto: IPerformancePostDto = {
      logoId: this.logoId,
      seasonId: this.selectedSeasonId,
      arFileSize: this.arFileSize?.toString() || '',
      shortDescription: this.shortDescription,
      mapData: {
        mapTextureId: this.mapPreviewId,
        bottomLeftCoord: {
          latitude: this.bounds[0][0],
          longitude: this.bounds[0][1],
        },
        topRightCoord: {
          latitude: this.bounds[1][0],
          longitude: this.bounds[1][1],
        },
      },
      name: this.name,
      description: this.description,
      inspiredBy: this.inspiredBy,
      author: this.author,
      duration: this.duration,
      distance: this.distance,
      minAge: this.minAge,
      backgroundColor: this.backgroundColor.toUpperCase(),
      textColor: this.textColor.toUpperCase(),
      isComingSoon: this.isComingSoon,
      isArchival: this.isArchival,
      idEvents: this.eventIds,
      checkpoints: this.checkpoints.map((item) => item.getPostDto()),
    };

    return postDto;
  }

  public getPutDto(): IPerformancePutDto {
    this.mapPreviewWasUpdate = false;

    const putDto: IPerformancePutDto = {
      id: this.id,
      logoId: this.logoId,
      seasonId: this.selectedSeasonId,
      mapData: {
        mapTextureId: this.mapPreviewId,
        bottomLeftCoord: {
          latitude: this.bounds[0][0],
          longitude: this.bounds[0][1],
        },
        topRightCoord: {
          latitude: this.bounds[1][0],
          longitude: this.bounds[1][1],
        },
      },
      name: this.name,
      description: this.description,
      shortDescription: this.shortDescription,
      arFileSize: this.arFileSize?.toString() || '',
      inspiredBy: this.inspiredBy,
      author: this.author,
      duration: this.duration,
      distance: this.distance,
      minAge: this.minAge,
      backgroundColor: this.backgroundColor,
      textColor: this.textColor,
      isComingSoon: this.isComingSoon,
      isArchival: this.isArchival,
      idEvents: this.eventIds,
      deleteCheckpoints: this.deleteCheckpoints,
      createCheckpoints: this.checkpoints.filter((item) => !item.id).map((item) => item.getPostDto()),
      checkpoints: this.checkpoints.filter((item) => item.id).map((item) => item.getPutDto()),
    };

    return putDto;
  }

  public selectTab(selectedTab: PerformanceTab) {
    this.selectedTab = selectedTab;
  }

  public setScreenShot(url: string) {
    this.screenURL = url;
    this.mapPreviewWasUpdate = true;
  }

  public sortCheckPoints() {
    this.checkpoints = this.checkpoints.sort((a, b) => ((a.number || 0) > (b.number || 0) ? 1 : -1));
  }

  public addCheckpoint() {
    const numbers = this.checkpoints.length > 0 ? this.checkpoints.map((item) => item.number || 0) : [0];
    this.checkpoints.push(new CheckPointModel(Math.max(...numbers) + 1, this));
    this.calculateDistance();
  }

  public removeCheckPoint(number: number | null) {
    const found = this.checkpoints.find((item) => item.number === number);

    if (found && found.id) {
      this.deleteCheckpoints.push(found.id);
    }

    this.checkpoints = this.checkpoints.filter((item) => item.number !== number);
    this.calculateDistance();
  }

  public onChangeName(value: string) {
    this.name = value;
  }

  public logoFile: any = null;

  public mapFile: any = null;

  public previewLogoImage = '';

  public previewMapImage = '';

  public setLogoImage(event: any) {
    this.logoFile = event.target.files[0];
    this.previewLogoImage = URL.createObjectURL(event.target.files[0]);
    event.target.value = '';
  }

  public setMapImage(event: any) {
    this.mapFile = event.target.files[0];
    this.previewMapImage = URL.createObjectURL(event.target.files[0]);

    event.target.value = '';
  }

  public onChangeInspiredBy(value: string) {
    this.inspiredBy = value;
  }

  public onChangeFileSize(value: number | null) {
    this.arFileSize = value;
  }

  public onChangeAuthor(value: string) {
    this.author = value;
  }

  public onChangeDescription(value: string) {
    this.description = value;
  }

  public onChangeShortDescription(value: string) {
    this.shortDescription = value;
  }

  public onChangeDuration(value: number | null) {
    this.duration = value;
  }

  public onChangeDistance(value: number) {
    this.distance = value;
  }

  public onChangeMinAge(value: number | null) {
    this.minAge = value;
  }

  public onChangeBackgroundColor(value: string) {
    this.backgroundColor = value;
  }

  public onChangeTextColor(value: string) {
    this.textColor = value;
  }

  public onChangeIsComingSoon(value: boolean) {
    this.isComingSoon = value;
  }

  public onChangeIsArchive(value: boolean) {
    this.isArchival = value;
  }

  public deInit() {
    this.id = '';
    this.logoFile = null;
    this.previewLogoImage = '';
    this.previewMapImage = '';
    this.logoId = '';
    this.mapPreviewId = '';
    this.name = 'Название спектакля';
    this.inspiredBy = 'По мотивам пьесы...';
    this.author = 'Автор';
    this.description = 'В период драматической эпохи...';
    this.shortDescription = '';
    this.bounds = [
      [56.47750562101612, 84.94094556607472],
      [56.48916474391545, 84.95497888364063],
    ];
    this.eventIds = '';
    this.duration = 60;
    this.distance = 0;
    this.minAge = 12;
    this.backgroundColor = '#1E4A48';
    this.textColor = '#FEFEFE';
    this.isComingSoon = false;
    this.isArchival = false;
    this.screenURL = '';
    this.mapPreviewWasUpdate = false;
    this.arFileSize = 0;
    this.selectedSeasonId = '';
    this.checkpoints = [new CheckPointModel(1, this)];
    this.deleteCheckpoints = [];
    this.seasonsList = [];
    this.selectedTab = PerformanceTab.CommonInfo;
  }
}
