import { TextM, TextS } from '../TextWrappers';

import c from './formRow.module.scss';

interface IProps {
  additionalClass?: string;
  label?: string;
  labelOnTop?: boolean;
  complexLabel?: JSX.Element;
  hasTopDivider?: boolean;
  hasBottomDivider?: boolean;
  required?: boolean;
  input: JSX.Element | null;
  validationMessage?: string | undefined;
  inputWidthInPercents?: number;
}

const FormRow = (props: IProps) => {
  const {
    additionalClass,
    complexLabel,
    hasTopDivider,
    hasBottomDivider,
    label,
    labelOnTop,
    input,
    validationMessage,
    required,
    inputWidthInPercents,
  } = props;

  let classes = labelOnTop ? c.verticalRow : `${c.formRowWrapper}`;

  if (hasTopDivider) {
    classes += ` ${c.topDivider}`;
  }

  if (hasBottomDivider) {
    classes += ` ${c.bottomDivider}`;
  }

  if (additionalClass) {
    classes += ` ${additionalClass}`;
  }

  const inputStyledByProps = inputWidthInPercents ? { width: `${inputWidthInPercents}%` } : {};

  return (
    <>
      <div className={classes}>
        {(complexLabel || label) && (
          <TextM className={labelOnTop ? c.topLabelWrapper : c.labelWrapper}>
            {<TextM text={label} />}
            {required && <TextM className={c.requiredSign} text={'*'} />}
          </TextM>
        )}

        <div className={labelOnTop ? c.fullWidthInputWrapper : c.inputWrapper} style={inputStyledByProps}>
          {input}
        </div>
      </div>

      <TextS text={validationMessage} className={hasTopDivider ? c.validationNoticeUnderLine : c.validationNotice} />
    </>
  );
};

export default FormRow;
