import dayjs from 'dayjs';

export class Utils {
  public static getRequestParamCase(paramName: string) {
    return `${paramName[0].toUpperCase()}${paramName.substring(1, paramName.length)}`;
  }

  public static getEndOfDay(date: Date | string) {
    return dayjs(date).add(23, 'hour').add(59, 'minute').add(59, 'second').add(59, 'millisecond').toISOString();
  }

  public static declOfNum = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];

    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
  };

  public static downloadFile = async (data: any, fileName: string, format: string) => {
    try {
      const utfCode = '\uFEFF';
      const blob = new Blob([utfCode + data], { type: 'application/text' });
      const url = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}.${format}`;
      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Добавление/замена параметра url
   * @param key {string} - параметр
   * @param value {string} - значение
   */
  public static insertUrlParam(key: string, value: string) {
    const url = new URL(document.location.href);

    if (Array.isArray(value)) {
      value = encodeURIComponent(JSON.stringify(value));
    }

    if (url.searchParams.has(key)) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.append(key, value);
    }

    window.history.replaceState(null, key, url.href);
  }

  /**
   * Возвращает get параметр url по имени
   * @param {string} key - имя параметра
   * @return {string} значение параметра
   */
  public static getUrlParam(key: string): string | null {
    const url = new URL(document.location.href);
    return url.searchParams.get(key);
  }

  public static removeUrlParam(key: string) {
    const url = new URL(document.location.href);
    if (!url.searchParams.has(key)) {
      return;
    }

    url.searchParams.delete(key);

    window.history.replaceState(null, 'key', url.href);
  }

  public static removeAllParams() {
    const url = new URL(document.location.href);

    url.searchParams.forEach((param, key) => {
      url.searchParams.delete(key);
    });

    window.history.replaceState(null, 'key', url.href);
  }

  public static ascCompare(a: any, b: any, propertyName: string) {
    const result = a[propertyName]! > b[propertyName]! ? 1 : -1;

    return result;
  }

  public static descCompare(a: any, b: any, propertyName: string) {
    const result = a[propertyName]! > b[propertyName]! ? -1 : 1;

    return result;
  }

  public static getDistance(lat1: number | null, long1: number | null, lat2: number | null, long2: number | null) {
    if (!lat1 || !lat2 || !long1 || !long2) return 0;

    const R = 6372795;
    //перевод коордитат в радианы
    lat1 *= Math.PI / 180;
    lat2 *= Math.PI / 180;
    long1 *= Math.PI / 180;
    long2 *= Math.PI / 180;
    //вычисление косинусов и синусов широт и разницы долгот
    const cl1 = Math.cos(lat1);
    const cl2 = Math.cos(lat2);
    const sl1 = Math.sin(lat1);
    const sl2 = Math.sin(lat2);
    const delta = long2 - long1;
    const cdelta = Math.cos(delta);
    const sdelta = Math.sin(delta);
    //вычисления длины большого круга
    const y = Math.sqrt(Math.pow(cl2 * sdelta, 2) + Math.pow(cl1 * sl2 - sl1 * cl2 * cdelta, 2));
    const x = sl1 * sl2 + cl1 * cl2 * cdelta;
    const ad = Math.atan2(y, x);
    const dist = ad * R; //расстояние между двумя координатами в метрах

    return dist;
  }
}
