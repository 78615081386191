import { makeAutoObservable } from 'mobx';

export enum ValidStatus {
  Success = 'Success',
  Error = 'Error',
  Warning = 'Warning',
  Unknown = 'Unknown',
}

export enum Rule {
  MinLength = 'MinLength',
  MaxLength = 'MaxLength',
  Requirement = 'Requirement',
  Regex = 'Regex',
}

interface IValidState {
  rule: Rule;
  notice: string;
  payload?: any;
  validStatus: ValidStatus;
}

export interface ICheckRequirements {
  rules: IRule[];
  payload?: any;
  validStatus: ValidStatus;
  currentNotice?: string;
}

export interface ICheckResult {
  validStatus: ValidStatus;
  currentNotice?: string;
}

export interface IRule {
  rule: Rule;
  regexRule?: any;
  payload?: any;
  description: string;
}

class ValidationModel {
  constructor(public checkRequirements: ICheckRequirements[]) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public initValidationList(checkRequirements: ICheckRequirements[]) {
    this.checkRequirements = checkRequirements;
  }

  public isValidationActivated = false;

  public modelIsNotValid = false;

  public result: IValidState[] = [];

  public activateValidation(valuesToCheck: any[], excludeCheckRequirementsByIndex?: number[]) {
    this.isValidationActivated = true;
    this.checkForValid(valuesToCheck, excludeCheckRequirementsByIndex);
  }

  public checkForValid(valuesToCheck: any[], excludeCheckRequirementsByIndex?: number[]) {
    if (!this.isValidationActivated) return;

    try {
      this.checkRequirements.forEach((item, index) => {
        if (!excludeCheckRequirementsByIndex?.includes(index)) {
          for (let i = 0; i < item.rules.length; i++) {
            const result = ValidationModel.checkRule(item.rules[i], valuesToCheck[index]);

            this.checkRequirements[index].validStatus = result.validStatus;
            this.checkRequirements[index].currentNotice = result.currentNotice;

            if (result.validStatus === ValidStatus.Error) {
              break;
            }

            if (result.validStatus === ValidStatus.Success) {
              this.checkRequirements[index].currentNotice = '';
            }
          }
        }
      });

      this.modelIsNotValid = this.checkRequirements.some((item) => item.validStatus === ValidStatus.Error);
    } catch (e) {
      console.log('Произошла ошибка при валидации модели');
      console.log(e);
    }
  }

  private static checkRule(rule: IRule, value: string): ICheckResult {
    const result: any = { validStatus: ValidStatus.Unknown, currentNotice: rule.description };

    if (rule.rule === Rule.MinLength) {
      result.validStatus = value.length >= (rule.payload as number) ? ValidStatus.Success : ValidStatus.Error;
    }

    if (rule.rule === Rule.MaxLength) {
      result.validStatus = value.length <= (rule.payload as number) ? ValidStatus.Success : ValidStatus.Error;
    }

    if (rule.rule === Rule.Requirement) {
      result.validStatus = !!value ? ValidStatus.Success : ValidStatus.Error;
    }

    if (rule.rule === Rule.Regex && rule.regexRule) {
      const regex = new RegExp(rule.regexRule);
      result.validStatus = regex.test(value) ? ValidStatus.Success : ValidStatus.Error;
    }

    return result;
  }

  public deInit() {
    this.isValidationActivated = false;
    this.modelIsNotValid = false;
    this.checkRequirements = this.checkRequirements.map((item) => ({
      ...item,
      validStatus: ValidStatus.Unknown,
      currentNotice: '',
    }));
  }
}

export default ValidationModel;
