import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';

import { AuthPage, MainPage, NoAccessPage } from '../index';

import { authUserStore } from 'stores';

import { appRoutes } from 'shared/constants/appRoutes';
import ConfirmPage from '../ConfirmPage/ConfirmPage';

import c from './router.module.scss';

const Router = () => {
  const history = useHistory();

  useEffect(() => {
    if (authUserStore.userInfoModel.token) return;

    authUserStore.userInfoModel.getUserInfoFromLocalStorage();
  }, []);

  useEffect(() => {
    if (history && !authUserStore.authUser.authSuccess) {
      history.push(appRoutes.auth);
    }
  }, [authUserStore.authUser.authSuccess]);

  return (
    <div className={c.routerWrapper}>
      <BrowserRouter>
        <div className={c.bodyContent}>
          <Switch>
            <Route exact={true} path={appRoutes.auth}>
              <AuthPage />
            </Route>

            <Route exact={true} path={appRoutes.main}>
              {authUserStore.isUserHasAccess(appRoutes.main) ? <MainPage /> : <NoAccessPage />}
            </Route>

            <Route exact={true} path={appRoutes.confirm}>
              <ConfirmPage />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default observer(Router);
