import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { FormRow, IconBtn, Spinner, TextInput } from 'components';

import { authUserStore } from 'stores';

import { TextL } from 'components/TextWrappers';

import { ConfirmPageMode } from 'shared/enums';
import { appRoutes } from 'shared/constants/appRoutes';

import c from './registrationForm.module.scss';

const RegistrationForm = () => {
  const history = useHistory();

  useEffect(() => {
    if (authUserStore.confirmPageMode === ConfirmPageMode.AfterRegistration) {
      history.push(appRoutes.confirm);
    }
  }, [authUserStore.confirmPageMode]);

  useEffect(() => {
    return () => {
      authUserStore.userRegModel.deInit();
    };
  }, []);

  return (
    <div className={c.regFormWrapper}>
      <TextL className={c.formTitle} text={'Регистрация'} />
      <div className={c.formContent}>
        <Spinner isLoading={authUserStore.isPending} />

        <FormRow
          label={'Фамилия'}
          validationMessage={authUserStore.userRegModel.validation.checkRequirements[0].currentNotice}
          input={
            <TextInput
              value={authUserStore.userRegModel.lastName}
              validStatus={authUserStore.userRegModel.validation.checkRequirements[0].validStatus}
              onChange={authUserStore.userRegModel.onChangeLastName}
            />
          }
        />

        <FormRow
          label={'Имя'}
          validationMessage={authUserStore.userRegModel.validation.checkRequirements[1].currentNotice}
          input={
            <TextInput
              value={authUserStore.userRegModel.firstName}
              validStatus={authUserStore.userRegModel.validation.checkRequirements[1].validStatus}
              onChange={authUserStore.userRegModel.onChangeFirstName}
            />
          }
        />

        <FormRow
          label={'Отчество'}
          validationMessage={authUserStore.userRegModel.validation.checkRequirements[2].currentNotice}
          input={
            <TextInput
              value={authUserStore.userRegModel.secondName}
              validStatus={authUserStore.userRegModel.validation.checkRequirements[2].validStatus}
              onChange={authUserStore.userRegModel.onChangeSecondName}
            />
          }
        />

        <FormRow
          label={'Email'}
          validationMessage={authUserStore.userRegModel.validation.checkRequirements[3].currentNotice}
          input={
            <TextInput
              value={authUserStore.userRegModel.email}
              validStatus={authUserStore.userRegModel.validation.checkRequirements[3].validStatus}
              onChange={authUserStore.userRegModel.onChangeEmail}
            />
          }
        />

        <FormRow
          label={'Пароль'}
          validationMessage={authUserStore.userRegModel.validation.checkRequirements[4].currentNotice}
          input={
            <TextInput
              value={authUserStore.userRegModel.password}
              validStatus={authUserStore.userRegModel.validation.checkRequirements[4].validStatus}
              onChange={authUserStore.userRegModel.onTypePassword}
              password={!authUserStore.userRegModel.isShowPassword}
              endAdornment={
                <IconBtn
                  className={c.showPswBtn}
                  icon={authUserStore.userRegModel.isShowPassword ? <Visibility /> : <VisibilityOff />}
                  onMouseDown={authUserStore.userRegModel.showPassword}
                  onMouseUp={authUserStore.userRegModel.hidePassword}
                  onMouseLeave={authUserStore.userRegModel.hidePassword}
                />
              }
            />
          }
        />

        <FormRow
          label={'Повторить пароль'}
          validationMessage={authUserStore.userRegModel.validation.checkRequirements[5].currentNotice}
          input={
            <TextInput
              value={authUserStore.userRegModel.repeatedPassword}
              validStatus={authUserStore.userRegModel.validation.checkRequirements[5].validStatus}
              onChange={authUserStore.userRegModel.onTypeRepeatedPassword}
              password={!authUserStore.userRegModel.isShowRepeatedPassword}
              endAdornment={
                <IconBtn
                  className={c.showPswBtn}
                  icon={authUserStore.userRegModel.isShowRepeatedPassword ? <Visibility /> : <VisibilityOff />}
                  onMouseDown={authUserStore.userRegModel.showRepeatedPassword}
                  onMouseUp={authUserStore.userRegModel.hideRepeatedPassword}
                  onMouseLeave={authUserStore.userRegModel.hideRepeatedPassword}
                />
              }
            />
          }
        />
      </div>
    </div>
  );
};

export default observer(RegistrationForm);
