export enum UIElemSizes {
  Sm = 'Sm',
  Md = 'Md',
  Lg = 'Lg',
}

export enum FontWeight {
  Thin = 100,
  Light = 200,
  Regular = 300,
  Medium = 400,
  DemiBold = 500,
  Bold = 600,
}

export enum UserType {
  Admin = 'Employee',
  Spectator = 'Spectator',
}

export enum TicketState {
  All = 'All',
  Activated = 'Activated',
  NonActivated = 'NonActivated',
  Refunded = 'Refunded',
}

export enum SelectedGroup {
  All = 'all',
  SelectedOnly = 'SelectedOnly',
  UnselectedOnly = 'UnselectedOnly',
}

export enum ConfirmPageMode {
  NotAvailable = 'NotAvailable',
  AfterRegistration = '1',
  AfterPasswordRecovering = '2',
}

export enum ConfirmStatus {
  InProcess = 'InProcess',
  Success = 'Success',
  Failed = 'Failed',
}

export enum TabNames {
  Users = 'Пользователи',
  Tickets = 'Билеты',
  Shows = 'Спектакли',
  Info = 'Информация',
}

export enum UserUpdateMode {
  PasswordOnly = 'PasswordOnly',
  InfoOnly = 'InfoOnly',
  InfoAndPassword = 'InfoAndPassword',
}

export enum SnackBarStates {
  Error = 'error',
  Success = 'success',
}

export enum TextAlign {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export enum ColorsVars {
  Error = '--red',
  Blue = '--blue',
  Green = '--green',
  TypoPrimary = '--typo-primary',
  TypoWhite = '--typo-white',
}

export enum PerformanceTab {
  CommonInfo = 'CommonInfo',
  Colors = 'Colors',
  CheckPoints = 'CheckPoints',
}
