import { makeAutoObservable } from 'mobx';

import { ticketsApiService } from 'apiServices';

import { appStore } from './index';

import { ITicketsStoreDI } from 'shared/interfaces/app/di';
import TicketRowModel from 'shared/models/tickets/TicketRowModel';
import TicketsRequestParamsControl from 'shared/models/tickets/TicketsRequestParamsControl';
import { SnackBarStates, TabNames } from '../shared/enums';
import { Debounce } from '../shared/models/serve';

class TicketsStore implements ITicketsStoreDI {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public debounce = new Debounce();

  private startRecordNumber = 0;

  public totalDataLength = 0;

  public tickets: TicketRowModel[] = [];

  public requestParamsControl = new TicketsRequestParamsControl(this, 15, this.tickets.length);

  public async setStartRecordNumber(startFrom: number, preventRequest?: boolean) {
    this.startRecordNumber = startFrom;

    if (startFrom === 0) {
      this.tickets = [];
    }

    if (preventRequest) return;

    const isTimeToGetRequest = await this.debounce.getTerm(
      this.requestParamsControl.filtersControl.hasAnyAppliedFilters ? 500 : 0
    );
    if (!isTimeToGetRequest) return;

    await this.getTickets();
  }

  public getNextPage() {
    this.startRecordNumber = this.tickets.length;
    this.getTickets();
  }

  public async doRefund(ticketId: string) {
    try {
      this.isPending = true;

      const isSuccess = await ticketsApiService.refund(ticketId);
      if (isSuccess) {
        const found = this.tickets.find((item) => item.id === ticketId);
        if (found) {
          found.setAsRefund();
        }
      }

      appStore.showSnackbar(SnackBarStates.Success, 'Статус билета успешно изменён');
    } catch (e) {
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при изменении статуса билета');
      console.log(e);
    } finally {
      this.isPending = false;
    }
  }

  public async getTickets() {
    try {
      this.isPending = true;

      const queryParams = this.requestParamsControl.getRequestParams();

      const ticketsDto = await ticketsApiService.getTickets(queryParams);
      if (!ticketsDto) return;

      this.totalDataLength = ticketsDto.totalCount;

      const newPageData = ticketsDto.models.map(
        (item, index) => new TicketRowModel(this.tickets.length + index + 1, item)
      );

      this.tickets.push(...newPageData);
    } catch (e) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, 'Произошла ошибка при получении списка билетов');
    } finally {
      this.isPending = false;
    }
  }

  public deInit(preventRequest?: boolean) {
    this.requestParamsControl.deInit(TabNames.Tickets, preventRequest);

    this.isPending = false;
    this.startRecordNumber = 0;
    this.totalDataLength = 0;
    this.tickets = [];

    this.debounce.deInitDebounce();
  }
}

export default new TicketsStore();
