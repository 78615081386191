import { TextL } from '../TextWrappers';
import { TabNames, UIElemSizes } from 'shared/enums';
import { Spinner } from '../index';

import React from 'react';

import c from './tabHeadline.module.scss';

interface IProps {
  name: TabNames;
  isPending: boolean;
  freeContent?: JSX.Element;
}

const TabHeadLine = (props: IProps) => {
  const { name, isPending, freeContent } = props;

  return (
    <div className={c.titleWrapper}>
      {freeContent}
      <TextL className={c.title} text={name} />

      <div className={c.spinnerWrapper}>
        <Spinner isLoading={isPending} size={UIElemSizes.Sm} />
      </div>
    </div>
  );
};

export default TabHeadLine;
