import BaseApiService from 'apiServices/BaseApiService';
import {
  IAuthResponse,
  IRecoveryPasswordPutDto,
  IUserAuthPostDto,
  IUserInfoPostDto,
} from '../shared/interfaces/api/authUser';

class AuthApiService extends BaseApiService {
  constructor() {
    super('api/EmployeeAccount');
  }

  public login(authData: IUserAuthPostDto): Promise<IAuthResponse> {
    return this.POST('Login', authData);
  }

  public register(userInfoDto: IUserInfoPostDto) {
    return this.POST('Register', userInfoDto);
  }

  public passwordRecovery(newAuthDataDto: IRecoveryPasswordPutDto) {
    return this.PUT('PasswordRecovery', newAuthDataDto);
  }

  public registerConfirmEmail(userId: string, token: string) {
    const encodedToken = encodeURIComponent(token);
    const encodedUserId = encodeURIComponent(userId);

    return this.GET(`RegisterConfirmEmail?userId=${encodedUserId}&token=${encodedToken}`);
  }

  public passwordRecoveryConfirmEmail(userId: string, token: string, password: string) {
    const encodedToken = encodeURIComponent(token);
    const encodedUserId = encodeURIComponent(userId);
    const encodedPassword = encodeURIComponent(password);

    return this.GET(
      `PasswordRecoveryConfirmEmail?userId=${encodedUserId}&token=${encodedToken}&newPassword=${encodedPassword}`
    );
  }
}

export default new AuthApiService();
