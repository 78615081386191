import dayjs from 'dayjs';

import BaseApiService from 'apiServices/BaseApiService';

import { Utils } from 'services/utils';
import { IUserInfoPutDto } from 'shared/interfaces/api/authUser';
import { IUserResponseDto } from 'shared/interfaces/api/users';

class UserApiService extends BaseApiService {
  constructor() {
    super('api/User');
  }

  public updatePersonalInfo(userInfo: IUserInfoPutDto): Promise<any> {
    return this.PUT('Update', userInfo);
  }

  public getUsersAccounts(filterParams: any): Promise<IUserResponseDto> {
    const utcFrom = filterParams.registrationDateFrom ? dayjs(filterParams.registrationDateFrom).toISOString() : '';
    const utcTo = filterParams.registrationDateTo ? Utils.getEndOfDay(filterParams.registrationDateTo) : '';

    const paramsWithUTSTime: any = {
      ...filterParams,
      registrationDateFrom: utcFrom,
      registrationDateTo: utcTo,
    };

    return this.POST(`GetByFilter`, paramsWithUTSTime);
  }

  public confirmUser(userId: string): Promise<boolean> {
    return this.PUT(`Confirm/?id=${userId}`);
  }

  public restoreUser(userId: string): Promise<boolean> {
    return this.PUT(`Restore/${userId}`);
  }

  public removeUser(userIds: string[]): Promise<boolean> {
    return this.DELETE(``, userIds);
  }
}

export default new UserApiService();
