import { Utils } from 'services/utils';
import LocalStorageService from 'services/LocalStorageService';

import { SortControl } from 'shared/models/serve';
import { SortDirection } from 'shared/models/serve/SortControl';

import { appStore } from 'stores';

import { TabNames, TicketState } from 'shared/enums';
import { ITicketsStoreDI } from 'shared/interfaces/app/di';

import { TicketsFiltersControl } from './TicketsFiltersControl';
import { ITicketsParamsPostDto } from 'shared/interfaces/api/tickets';

export default class TicketsRequestParamsControl {
  constructor(
    private ticketsStore: ITicketsStoreDI,
    private virtualPageLength: number,
    private nextPageRecordStartFrom: number
  ) {}

  public filtersControl = new TicketsFiltersControl(this.ticketsStore);

  public sortControl = new SortControl({ columnName: 'Number', direction: SortDirection.Desc }, this.ticketsStore);

  public initFromURL(tabId: TabNames) {
    const requestParams: any = this.getRequestParams(true);

    try {
      for (const requestParamsKey in requestParams) {
        const trueCased = Utils.getRequestParamCase(requestParamsKey);

        const paramValueFromURL = Utils.getUrlParam(trueCased);
        if (paramValueFromURL?.toString()) {
          requestParams[requestParamsKey] = paramValueFromURL;
        }
      }

      this.initParams(tabId, requestParams);
    } catch (e) {
      console.log(e);
    }
  }

  private initParams(tabId: TabNames, requestParams: ITicketsParamsPostDto) {
    this.filtersControl.init(requestParams);

    if (!this.filtersControl.hasAnyAppliedFilters) {
      const paramsFromLocalStorage = LocalStorageService.getDataFromLocalStorageByKey<ITicketsParamsPostDto>(
        `filterParamsFor_${tabId}`
      );

      if (paramsFromLocalStorage) {
        this.filtersControl.init(paramsFromLocalStorage);
      }
    }

    this.sortControl.init(requestParams.sortField, requestParams.sortByDesc.toString() === 'true');
  }

  public setStateToURL(requestParams: any) {
    const excludeList = ['from', 'count', 'sortbydesc', 'sortfield'];

    try {
      for (const requestParamsKey in requestParams) {
        if (excludeList.includes(requestParamsKey.toLowerCase())) continue;

        const param = requestParams[requestParamsKey]?.toString();
        const trueCased = Utils.getRequestParamCase(requestParamsKey);

        if (param) {
          Utils.insertUrlParam(trueCased, param);
        }

        if (!param) {
          Utils.removeUrlParam(trueCased);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  public getRequestParams(preventPutToURL?: boolean): ITicketsParamsPostDto {
    const requestParams: ITicketsParamsPostDto = {
      from: this.ticketsStore.tickets.length,
      count: this.virtualPageLength,
      sortByDesc: this.sortControl.direction === SortDirection.Desc,
      sortField: this.sortControl.columnName,
      spectatorEmail: this.filtersControl.spectatorEmail,
      performanceId: this.filtersControl.performanceId,
      number: this.filtersControl.number,
      spectatorPhone: this.filtersControl.spectatorPhone,
      state: this.filtersControl.state === TicketState.All ? null : this.filtersControl.state,
      hasActivationTime: this.filtersControl.hasActivationTime,
      activationTimeFrom: this.filtersControl.activationTimeFrom,
      activationTimeTo: this.filtersControl.activationTimeTo,
    };

    if (!preventPutToURL) {
      this.setStateToURL(requestParams);
    }

    return requestParams;
  }

  public deInit(tabId: TabNames, preventRequest?: boolean) {
    const allParams = this.getRequestParams(true);
    LocalStorageService.putDataToLocalStorage(`filterParamsFor_${tabId}`, allParams);

    this.filtersControl.deInit(preventRequest);
    this.sortControl.deInit();

    if (tabId === appStore.selectedTabId) {
      return;
    }

    for (const allParamsKey in allParams) {
      Utils.removeUrlParam(Utils.getRequestParamCase(allParamsKey));
    }
  }
}
