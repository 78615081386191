import { useRef, useState } from 'react';
import { observer } from 'mobx-react';

import { Map, Placemark, YMaps } from '@pbe/react-yandex-maps';

import { appStore, performanceStore } from 'stores';

import { TextM, TextS } from 'components/TextWrappers';
import { ColorsVars, SnackBarStates } from 'shared/enums';
import { PrimaryButton } from 'components';

import html2canvas from 'html2canvas';

import c from './map.module.scss';

const MapWrapper = () => {
  const mapWrapperRef = useRef<any>();
  const mapRef = useRef<any>();

  const takeScreenshot = async () => {
    const input = document.getElementById('divToPrint');
    if (!input) return;

    const result = await html2canvas(input);
    const imgData = result.toDataURL('image/png');

    performanceStore.performance.setScreenShot(imgData);
    appStore.showSnackbar(SnackBarStates.Success, 'Скриншот карты успешно сделан, перейдите на превью для просмотра');
  };

  const onMouseDown = () => {
    const bb = mapRef.current.getBounds();
    performanceStore.performance.setBounds(bb);
  };

  const onMouseUp = () => {
    const bb = mapRef.current.getBounds();
    performanceStore.performance.setBounds(bb);
  };

  const onWheel = () => {
    setTimeout(() => {
      const bb = mapRef.current.getBounds();
      performanceStore.performance.setBounds(bb);
    }, 500);
  };

  return (
    <div className={c.mapOuterWrapper}>
      {/*<PrimaryButton onClick={takeScreenshot} caption={'Сделать скриншот карты для превью'} />*/}

      <div className={c.distanceInfo}>
        <TextS color={ColorsVars.TypoPrimary} text={'Расстояние маршрута'} />
        <TextM color={ColorsVars.TypoPrimary} text={performanceStore.performance.distanceFormatted} />
      </div>

      <div
        id="divToPrint"
        className={c.mapWrapper}
        ref={mapWrapperRef}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onWheel={onWheel}>
        <YMaps>
          <Map
            instanceRef={mapRef}
            width={327}
            height={491}
            defaultState={{
              bounds: performanceStore.performance.bounds,
            }}>
            {performanceStore.performance.checkpoints.map((item) => {
              return (
                <Placemark
                  key={item.id}
                  geometry={[item.lat, item.lng]}
                  options={{ draggable: true }}
                  properties={{ iconContent: item.number }}
                  instanceRef={(el) => item.setRef(el)}
                />
              );
            })}
          </Map>
        </YMaps>
      </div>
    </div>
  );
};

export default observer(MapWrapper);
