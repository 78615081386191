export class Debounce {
  public initialCounter = 0;

  public counter = 0;

  public async getTerm(delay: number): Promise<boolean> {
    this.counter += 1;

    return new Promise((resolve) => {
      setTimeout(
        () => {
          this.initialCounter += 1;
          const result = this.initialCounter === this.counter;
          resolve(result);
        },
        this.counter === 0 ? 0 : delay
      );
    });
  }

  public deInitDebounce() {
    this.initialCounter = 0;
    this.counter = 0;
  }
}

export default Debounce;
