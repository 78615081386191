import dayjs from 'dayjs';

import { Utils } from 'services/utils';
import BaseApiService from './BaseApiService';

import { IPerformanceResponseDto, IPerformanceRowDto } from 'shared/interfaces/api/performacnes/IPerfomanceGetDto';
import { IPerformancePostDto } from '../shared/interfaces/api/performacnes/IPerformancePostDto';

class PerformancesApiService extends BaseApiService {
  constructor() {
    super('api/Performance');
  }

  public getPerformances(filterParams: any): Promise<IPerformanceResponseDto> {
    const paramsWithUTSTime: any = {
      filterParams,
    };

    return this.POST(`GetByFilter`, filterParams);
  }

  public postPerformance(postDto: IPerformancePostDto): Promise<any> {
    return this.POST(``, postDto);
  }

  public putPerformance(postDto: IPerformancePostDto): Promise<any> {
    return this.PUT(``, postDto);
  }

  public getPerformanceById(id: string): Promise<IPerformanceRowDto> {
    return this.GET(`/${id}`);
  }

  public restorePerformance(performanceId: string) {
    return this.PUT(`Restore/?id=${performanceId}`);
  }

  public deletePerformance(performanceId: string) {
    return this.DELETE(`?id=${performanceId}`);
  }
}

export default new PerformancesApiService();
