import { makeAutoObservable } from 'mobx';

import AuthUser from 'shared/models/userAuth/UserAuthModel';

import { appStore } from './index';

import { ConfirmPageMode, ConfirmStatus, SnackBarStates } from 'shared/enums';

import { accountApiService } from '../apiServices';
import UserRecoveryPasswordModel from 'shared/models/userAuth/UserRecoveryPasswordModel';
import UserInfoModel from 'shared/models/userAuth/UserInfoModel';
import userApiService from '../apiServices/userApiService';

class AuthUserStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public isPending = false;

  public authUser = new AuthUser();

  public recoveryPasswordModel = new UserRecoveryPasswordModel();

  public userRegModel = new UserInfoModel();

  public userInfoModel = new UserInfoModel();

  public isShowRegistrationForm = false;

  public isShowRecoverPasswordForm = false;

  public isPasswordRecovering = false;

  public isShowUserInfo = false;

  public confirmPageMode = ConfirmPageMode.NotAvailable;

  public confirmStatus: ConfirmStatus | null = null;

  public lastEmail = '';

  public get currentStatusText(): string[] {
    let statusText = ['Результат не известен'];

    const reason = this.isPasswordRecovering ? 'восстановления пароля' : 'подтверждения регистрации';

    if (this.confirmStatus === ConfirmStatus.InProcess) {
      statusText = [`Обрабатываем ваш запрос ${reason}`];
    }

    if (this.confirmStatus === ConfirmStatus.Success) {
      statusText = [
        `Ваш запрос ${reason} успешно обработан.`,
        `Дождитесь пока администратор подтвердит вашу учетную запись.`,
      ];
    }

    if (this.confirmStatus === ConfirmStatus.Failed) {
      statusText = [`Произошла ошибка при обработке запроса ${reason}`];
    }

    return statusText;
  }

  public isUserHasAccess = (route: string): boolean => {
    if (!this.userInfoModel.token) {
      this.userInfoModel.getUserInfoFromLocalStorage();
    }

    return !!this.userInfoModel.token;
  };

  public showUserInfo() {
    this.isShowUserInfo = true;
  }

  public closeUserInfo() {
    this.isShowUserInfo = false;
  }

  public openRegistrationForm() {
    this.isShowRegistrationForm = true;
  }

  public closeRegistrationForm() {
    this.isShowRegistrationForm = false;
  }

  public openRecoveryPasswordForm() {
    this.isShowRecoverPasswordForm = true;
  }

  public closeRecoveryPasswordForm() {
    this.isShowRecoverPasswordForm = false;
  }

  /**
   * Запрос на регистрацию нового пользователя
   */
  public async submitRegistration() {
    try {
      const postDto = this.userRegModel.getUserPostDto();
      if (!postDto) return;

      this.isPending = true;

      await accountApiService.register(postDto);
      this.confirmPageMode = ConfirmPageMode.AfterRegistration;
      this.lastEmail = postDto.email;

      appStore.showSnackbar(SnackBarStates.Success, 'Запрос на регистрацию успешно отправлен', 6000);
      this.closeRegistrationForm();
    } catch (e: any) {
      appStore.showSnackbar(SnackBarStates.Error, e.response.data || 'Произошла непредвиденная ошибка');
    } finally {
      this.isPending = false;
    }
  }

  /**
   * Запрос на изменение пароля
   */
  public async submitRecoveryPassword() {
    try {
      const postDto = this.recoveryPasswordModel.getPutDto();
      if (!postDto) return;

      this.isPending = true;

      await accountApiService.passwordRecovery(postDto);

      this.confirmPageMode = ConfirmPageMode.AfterPasswordRecovering;
      this.lastEmail = postDto.email;

      appStore.showSnackbar(SnackBarStates.Success, 'Запрос на восстановление пароля успешно отправлен', 6000);
      this.closeRecoveryPasswordForm();
    } catch (e: any) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, e.response.data || 'Произошла непредвиденная ошибка');
    } finally {
      this.isPending = false;
    }
  }

  /**
   * Запрос на обновление учётных данных пользователя
   */
  public async submitUserInfoChanged() {
    try {
      const putDtoAndMode = this.userInfoModel.getUserPutDto();
      if (!putDtoAndMode) return;

      this.isPending = true;

      const responseDto = await userApiService.updatePersonalInfo(putDtoAndMode);
      this.userInfoModel.initBySuccessUpdate(responseDto);

      appStore.showSnackbar(SnackBarStates.Success, 'Запрос на обновление учётных данных успешно отправлен', 6000);
      this.closeUserInfo();
    } catch (e) {
    } finally {
      this.isPending = false;
    }
  }

  /**
   * Авторизация в приложении
   */
  public async signIn() {
    try {
      const postDto = this.authUser.getPostDto();
      if (!postDto) return;

      this.isPending = true;

      const responseDto = await accountApiService.login(postDto);
      this.userInfoModel.setTokens(responseDto);
      this.authUser.setAuthSuccess(true);
      this.userInfoModel.initBySuccessAuth(responseDto.user);

      appStore.dropSnackBarToDefault();
      this.authUser.deInit();
    } catch (e: any) {
      console.log(e);
      appStore.showSnackbar(
        SnackBarStates.Error,
        e.response.data || 'Произошла непредвиденная ошибка при попытке авторизации'
      );
    } finally {
      this.isPending = false;
    }
  }

  public async registrationConfirm(userId: string, token: string) {
    try {
      this.isPending = true;
      this.isPasswordRecovering = false;
      this.confirmStatus = ConfirmStatus.InProcess;
      await accountApiService.registerConfirmEmail(userId, token);
      this.confirmStatus = ConfirmStatus.Success;
    } catch (e: any) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, e.response.data);
      this.confirmStatus = ConfirmStatus.Failed;
    } finally {
      this.isPending = false;
    }
  }

  public async passwordRecoveryConfirm(userId: string, token: string, password: string) {
    try {
      this.isPasswordRecovering = true;

      this.isPending = true;
      this.confirmStatus = ConfirmStatus.InProcess;
      await accountApiService.passwordRecoveryConfirmEmail(userId, token, password);
      appStore.showSnackbar(SnackBarStates.Success, 'Ваш пароль подтверждён');
      this.confirmStatus = ConfirmStatus.Success;
    } catch (e: any) {
      console.log(e);
      appStore.showSnackbar(SnackBarStates.Error, e.response.data);
      this.confirmStatus = ConfirmStatus.Failed;
    } finally {
      this.isPending = false;
    }
  }

  public logOut(): boolean {
    this.authUser.logout();
    this.confirmStatus = null;
    this.confirmPageMode = ConfirmPageMode.NotAvailable;
    this.isPasswordRecovering = false;

    return true;
  }

  public dropAccessToConfirmPage() {
    this.confirmPageMode = ConfirmPageMode.NotAvailable;
    this.lastEmail = '';
  }
}

export default new AuthUserStore();
