import { makeAutoObservable } from 'mobx';

import { Utils } from 'services/utils';

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
  Undirected = 'undirected',
}

export interface ISortState {
  columnName: string;
  direction: SortDirection;
}

export default class SortControl<T> {
  constructor(initState: ISortState, private storeDI: T) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.initialColumnName = initState.columnName;
    this.columnName = initState.columnName;
    this.direction = initState.direction;
  }

  public direction: SortDirection = SortDirection.Undirected;

  public initialColumnName = '';

  public columnName = '';

  public init(column: string, isDesc: boolean) {
    this.columnName = column;
    if (isDesc) {
      this.direction = SortDirection.Desc;
    }

    if (!isDesc) {
      this.direction = SortDirection.Asc;
    }
  }

  public onSortClick(fieldName: string) {
    const columnNamePreparedForBackend = Utils.getRequestParamCase(fieldName);

    if (this.columnName !== columnNamePreparedForBackend) {
      this.direction = SortDirection.Undirected;
    }

    this.columnName = columnNamePreparedForBackend;

    if (this.direction === SortDirection.Undirected) {
      this.direction = SortDirection.Desc;
      (this.storeDI as any).setStartRecordNumber(0);

      return;
    }

    if (this.direction === SortDirection.Desc) {
      this.direction = SortDirection.Asc;
      (this.storeDI as any).setStartRecordNumber(0);

      return;
    }

    if (this.direction === SortDirection.Asc) {
      this.direction = SortDirection.Undirected;
      (this.storeDI as any).setStartRecordNumber(0);

      return;
    }
  }

  public deInit() {
    this.direction = SortDirection.Desc;
    this.columnName = this.initialColumnName;
  }
}
