import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import { FormRow, IconBtn, Spinner, TextInput } from 'components';
import { TextL } from 'components/TextWrappers';

import { authUserStore } from 'stores';

import { ConfirmPageMode } from 'shared/enums';
import { appRoutes } from 'shared/constants/appRoutes';

import c from './recoveryPasswordForm.module.scss';

const RecoveryPasswordForm = () => {
  const history = useHistory();

  useEffect(() => {
    return () => authUserStore.recoveryPasswordModel.deInit();
  }, []);

  useEffect(() => {
    if (authUserStore.confirmPageMode === ConfirmPageMode.AfterPasswordRecovering) {
      history.push(appRoutes.confirm);
    }
  }, [authUserStore.confirmPageMode]);

  return (
    <div className={c.recoveryPasswordFormWrapper}>
      <TextL className={c.formTitle} text={'Восстановление пароля'} />

      <div className={c.formContent}>
        <Spinner isLoading={authUserStore.isPending} />
        <FormRow
          label={'Email'}
          validationMessage={authUserStore.recoveryPasswordModel.validation.checkRequirements[0].currentNotice}
          input={
            <TextInput
              value={authUserStore.recoveryPasswordModel.email}
              validStatus={authUserStore.recoveryPasswordModel.validation.checkRequirements[0].validStatus}
              onChange={authUserStore.recoveryPasswordModel.onTypeEmail}
            />
          }
        />

        <FormRow
          label={'Новый пароль'}
          validationMessage={authUserStore.recoveryPasswordModel.validation.checkRequirements[1].currentNotice}
          input={
            <TextInput
              value={authUserStore.recoveryPasswordModel.newPassword}
              onChange={authUserStore.recoveryPasswordModel.onTypeNewPassword}
              password={!authUserStore.recoveryPasswordModel.isShowPassword}
              validStatus={authUserStore.recoveryPasswordModel.validation.checkRequirements[1].validStatus}
              endAdornment={
                <IconBtn
                  className={c.showPswBtn}
                  icon={authUserStore.recoveryPasswordModel.isShowPassword ? <Visibility /> : <VisibilityOff />}
                  onMouseDown={authUserStore.recoveryPasswordModel.showPassword}
                  onMouseUp={authUserStore.recoveryPasswordModel.hidePassword}
                  onMouseLeave={authUserStore.recoveryPasswordModel.hidePassword}
                />
              }
            />
          }
        />

        <FormRow
          label={'Повторить пароль'}
          validationMessage={authUserStore.recoveryPasswordModel.validation.checkRequirements[2].currentNotice}
          input={
            <TextInput
              value={authUserStore.recoveryPasswordModel.repeatedNewPassword}
              onChange={authUserStore.recoveryPasswordModel.onTypeRepeatedNewPassword}
              password={!authUserStore.recoveryPasswordModel.isShowRepeatedPassword}
              validStatus={authUserStore.recoveryPasswordModel.validation.checkRequirements[2].validStatus}
              endAdornment={
                <IconBtn
                  className={c.showPswBtn}
                  icon={authUserStore.recoveryPasswordModel.isShowRepeatedPassword ? <Visibility /> : <VisibilityOff />}
                  onMouseDown={authUserStore.recoveryPasswordModel.showRepeatedPassword}
                  onMouseUp={authUserStore.recoveryPasswordModel.hideRepeatedPassword}
                  onMouseLeave={authUserStore.recoveryPasswordModel.hideRepeatedPassword}
                />
              }
            />
          }
        />
      </div>
    </div>
  );
};

export default observer(RecoveryPasswordForm);
