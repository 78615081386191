import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';

import { IUserGetRowDto } from '../../interfaces/api/users';

export default class UserRowModel {
  constructor(throughNumber: number, dto: IUserGetRowDto) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.throughNumber = throughNumber;

    this.id = dto.id;

    this.firstName = dto.firstName;
    this.secondName = dto.secondName;
    this.lastName = dto.lastName;
    this.email = dto.email;
    this.registrationDateSource = dto.registrationDate;
    const timeZoneOffset = Math.abs(new Date(dto.registrationDate).getTimezoneOffset()) / 60;
    this.registrationDate = dayjs(dto.registrationDate).add(timeZoneOffset, 'hour').format('MM-DD-YYYY HH:mm');

    this.isDeleted = dto.isDeleted;
    this.isConfirmed = dto.isConfirmed;
  }

  public id = '';

  public isSelected = false;

  public throughNumber: number | null = null;

  public firstName = '';

  public secondName = '';

  public lastName = '';

  public company = '';

  public email = '';

  public userTypeName = '';

  public registrationDateSource = '';

  public registrationDate = '';

  public roles: any[] = [];

  public isBlocked = false;

  public isConfirmed = false;

  public isDeleted = false;

  public receiveNewCodes = false;

  public receiveUsersUpdate = false;

  public get rolesStr() {
    return this.roles.map((item) => item.toString());
  }

  public get tableRowTextColor() {
    if (this.isDeleted) {
      return '--disabled';
    }

    return '--typo-primary';
  }

  public setAsRemoved() {
    this.isDeleted = true;
  }

  public setAsRestored() {
    this.isDeleted = false;
  }

  public setAsBlocked() {
    this.isBlocked = true;
  }

  public setAsUnblocked() {
    this.isBlocked = false;
  }

  public setAsConfirmed() {
    this.isConfirmed = true;
  }
}
