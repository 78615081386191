import { IPerformanceStoreDI } from '../../interfaces/app/di';
import { SortControl } from '../serve';
import { SortDirection } from '../serve/SortControl';
import { TabNames } from '../../enums';
import { Utils } from '../../../services/utils';

import LocalStorageService from '../../../services/LocalStorageService';
import { appStore } from '../../../stores';
import { PerformanceFilterControl } from './PerformanceFilterControl';
import { IPerformancePostParamsDto } from '../../interfaces/api/performacnes';

export class PerformanceRequestParamsControl {
  constructor(private performanceStore: IPerformanceStoreDI, private virtualPageLength: number) {}

  public filtersControl = new PerformanceFilterControl(this.performanceStore);

  public sortControl = new SortControl({ columnName: 'Name', direction: SortDirection.Desc }, this.performanceStore);

  public initFromURL(tabId: TabNames) {
    const requestParams: any = this.getRequestParams(true);

    try {
      for (const requestParamsKey in requestParams) {
        const trueCased = Utils.getRequestParamCase(requestParamsKey);

        const paramValueFromURL = Utils.getUrlParam(trueCased);
        if (paramValueFromURL?.toString()) {
          requestParams[requestParamsKey] = paramValueFromURL;
        }
      }

      this.initParams(tabId, requestParams);
    } catch (e) {
      console.log(e);
    }
  }

  private initParams(tabId: TabNames, requestParams: IPerformancePostParamsDto) {
    this.filtersControl.init(requestParams);

    if (!this.filtersControl.hasAnyAppliedFilters) {
      const paramsFromLocalStorage = LocalStorageService.getDataFromLocalStorageByKey<IPerformancePostParamsDto>(
        `filterParamsFor_${tabId}`
      );

      if (paramsFromLocalStorage) {
        this.filtersControl.init(paramsFromLocalStorage);
      }
    }

    this.sortControl.init(requestParams.sortField, requestParams.sortByDesc.toString() === 'true');
  }

  public setStateToURL(requestParams: any) {
    const excludeList = ['from', 'count', 'sortbydesc', 'sortfield'];

    try {
      for (const requestParamsKey in requestParams) {
        if (excludeList.includes(requestParamsKey.toLowerCase())) continue;

        const param = requestParams[requestParamsKey]?.toString();
        const trueCased = Utils.getRequestParamCase(requestParamsKey);

        if (param) {
          Utils.insertUrlParam(trueCased, param);
        }

        if (!param) {
          Utils.removeUrlParam(trueCased);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  public getRequestParams(preventPutToURL?: boolean): IPerformancePostParamsDto {
    const requestParams: IPerformancePostParamsDto = {
      from: this.performanceStore.performances.length,
      count: this.virtualPageLength,
      sortByDesc: this.sortControl.direction === SortDirection.Desc,
      sortField: this.sortControl.columnName,
      creatorEmail: this.filtersControl.creatorEmail,
      name: this.filtersControl.name,
      description: this.filtersControl.description,
      inspiredBy: this.filtersControl.inspiredBy,
      author: this.filtersControl.author,
      durationFrom: this.filtersControl.durationFrom,
      durationTo: this.filtersControl.durationTo,
      distanceFrom: this.filtersControl.distanceFrom,
      distanceTo: this.filtersControl.distanceTo,
      minAgeFrom: this.filtersControl.minAgeFrom,
      minAgeTo: this.filtersControl.minAgeTo,
      backgroundColor: this.filtersControl.backgroundColor,
      textColor: this.filtersControl.textColor,
      isDeleted: this.filtersControl.isDeleted,
      isComingSoon: this.filtersControl.isComingSoon,
      isArchival: this.filtersControl.isArchival,
      idEvent: this.filtersControl.idEvent,
    };

    if (!preventPutToURL) {
      this.setStateToURL(requestParams);
    }

    return requestParams;
  }

  public deInit(tabId: TabNames, preventRequest?: boolean) {
    const allParams = this.getRequestParams(true);
    LocalStorageService.putDataToLocalStorage(`filterParamsFor_${tabId}`, allParams);

    this.filtersControl.deInit(preventRequest);
    this.sortControl.deInit();

    if (tabId === appStore.selectedTabId) {
      return;
    }

    for (const allParamsKey in allParams) {
      Utils.removeUrlParam(Utils.getRequestParamCase(allParamsKey));
    }
  }
}
