import ITextProps from '../ITextProps';

import { FontWeight } from 'shared/enums';

import c from './textTbCell.module.scss';

const TextTbCell = ({
  children,
  className,
  color,
  hexColor,
  fontWeight,
  text,
  textAlign,
  noWrap,
  unselectable,
  onDoubleClick,
}: ITextProps) => {
  const classes = unselectable
    ? `${className || ''} ${c.textTbCell} unselectable`
    : `${className || ''} ${c.textTbCell}`;

  return (
    <div
      onDoubleClick={onDoubleClick}
      className={classes}
      style={{
        textAlign: textAlign || 'initial',
        fontWeight: fontWeight || FontWeight.Light,
        color: hexColor ? hexColor : color ? `var(${color})` : '',
        whiteSpace: noWrap ? 'nowrap' : undefined,
      }}>
      {text || children}
    </div>
  );
};

export default TextTbCell;
