import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { TextField } from '@mui/material';

import { IconBtn } from '../Buttons';
import { ValidStatus } from 'shared/models/serve/ValidationModel';

import c from './number.module.scss';

interface IProps {
  shouldPrevent?: boolean;
  disabled?: boolean;
  validStatus?: ValidStatus;
  isHasClearBtn?: boolean;
  placeholder?: string;
  value: number | null;
  step?: number;
  startAdornment?: JSX.Element | null;
  endAdornment?: JSX.Element | null;
  onChange(value: number | null): void;
  onBlur?(): void;
  autoFocus?: boolean;
}

const NumberInput = (props: IProps) => {
  const {
    autoFocus,
    disabled,
    validStatus,
    isHasClearBtn,
    value,
    placeholder,
    startAdornment,
    endAdornment,
    shouldPrevent,
    step,
    onChange,
    onBlur,
  } = props;

  const onChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const parsed = parseFloat(event.target.value);
    if (!parsed.toString() || isNaN(parsed)) {
      onChange(null);
      return;
    }

    onChange(parsed);
  };

  const clearValue = () => {
    onChange(null);
  };

  let validStateClassName = '';

  switch (validStatus) {
    case ValidStatus.Unknown: {
      break;
    }

    case ValidStatus.Success: {
      validStateClassName += ` ${c.success}`;
      break;
    }

    case ValidStatus.Error: {
      validStateClassName += ` ${c.error}`;
      break;
    }

    case ValidStatus.Warning: {
      validStateClassName += ` ${c.warning}`;
      break;
    }
  }

  const clearBtn = <IconBtn className={c.clrBtn} icon={<CloseIcon className={c.clrBtnIcn} />} onClick={clearValue} />;

  const onClick = (e: React.MouseEvent) => {
    if (!shouldPrevent) return;
    e.stopPropagation();
  };

  return (
    <TextField
      onMouseDown={onClick}
      autoFocus={autoFocus}
      className={`${c.textInput} ${validStateClassName}`}
      onChange={onChangeValue}
      onBlur={onBlur}
      disabled={disabled}
      placeholder={placeholder}
      value={value?.toString() ? value : ''}
      variant="outlined"
      type={'number'}
      size="small"
      fullWidth
      inputProps={{ step: step }}
      InputProps={{
        startAdornment,
        endAdornment: endAdornment ? endAdornment : isHasClearBtn && !!value ? clearBtn : null,
      }}
    />
  );
};

export default NumberInput;
