import { makeAutoObservable } from 'mobx';

import { IPerformanceDI } from '../../interfaces/app/di';
import { ICheckPointPostDto, ICheckPointPutDto } from '../../interfaces/api/performacnes/IPerformancePostDto';
import { ICheckpointInfo } from '../../interfaces/api/performacnes';

export class CheckPointModel {
  constructor(number: number, private performanceModel: IPerformanceDI, lng?: number, lat?: number) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.number = number;

    this.name = `Контрольная точка №${number}`;
    this.address = 'Адрес точки';

    this.id = number.toString();

    if (lng && lat) {
      this.lat = lat;
      this.lng = lng;

      return;
    }

    if (number > 1) {
      this.lat = (this.lat || 0) - (number - 1) / 1000;
      this.lng = (this.lng || 0) + (number - 1) / 1000;
    }
  }

  public id = '';

  public number: number | null = null;

  public name = '';

  public address = '';

  public lat: number | null = 56.482928;

  public lng: number | null = 84.945986;

  public guideAudioClipId = '';

  public addressablePrefabId = '';

  public headingAngle = 0;

  public markerRef: any = null;

  public setRef(marker: any) {
    if (!marker) return;

    this.markerRef = marker;
    marker.events.add('drag', this.onChangePosition);
  }

  private onChangePosition(e: any) {
    const latLng = e._sourceEvent.originalEvent.target.geometry._coordinates;

    if (!latLng || latLng.length < 2) return;

    this.lat = Math.round(latLng[0] * 100000) / 100000;
    this.lng = Math.round(latLng[1] * 100000) / 100000;

    this.performanceModel.calculateDistance();
  }

  public initByDto(dto: ICheckpointInfo) {
    this.id = dto.id;
    this.lat = dto.modelCoords.latitude;
    this.lng = dto.modelCoords.longitude;
    this.address = dto.address;
    this.name = dto.name;
    this.number = dto.index;
    this.addressablePrefabId = dto.arModel.addressablePrefabId;
    this.headingAngle = dto.arModel.headingAngle;
    this.guideAudioClipId = dto.guideAudioClipId;
  }

  public getPostDto(): ICheckPointPostDto {
    const postDto: ICheckPointPostDto = {
      name: this.name,
      index: this.number,
      address: this.address,
      guideAudioClipId: this.guideAudioClipId,
      userStandCoords: {
        latitude: this.lat || 0,
        longitude: this.lng || 0,
      },
      modelCoords: {
        latitude: this.lat || 0,
        longitude: this.lng || 0,
      },
      arModel: {
        id: '',
        headingAngle: this.headingAngle,
        addressablePrefabId: this.addressablePrefabId,
      },
    };

    return postDto;
  }

  public getPutDto(): ICheckPointPutDto {
    const postDto: ICheckPointPutDto = {
      id: this.id,
      name: this.name,
      index: this.number,
      address: this.address,
      guideAudioClipId: this.guideAudioClipId,
      userStandCoords: {
        latitude: this.lat || 0,
        longitude: this.lng || 0,
      },
      modelCoords: {
        latitude: this.lat || 0,
        longitude: this.lng || 0,
      },
      arModel: {
        id: '',
        headingAngle: this.headingAngle,
        addressablePrefabId: this.addressablePrefabId,
      },
    };

    return postDto;
  }

  public onChangeName(value: string) {
    this.name = value;
  }

  public onChangeNumber(value: number) {
    this.number = value;
  }

  public onChangeAddress(value: string) {
    this.address = value;
  }

  public onChangeGuideAudioClipId(value: string) {
    this.guideAudioClipId = value;
  }

  public onChangeHeadingAngle(value: number | null) {
    this.headingAngle = value || 0;
  }

  public onChangeAddressablePrefabId(value: string) {
    this.addressablePrefabId = value;
  }

  public onChangeLat(value: number | null) {
    this.lat = value;
  }

  public onChangeLng(value: number | null) {
    this.lng = value;
  }
}
