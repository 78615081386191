class LocalStorageService {
  public static putDataToLocalStorage<T>(key: string, data: T): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public static getDataFromLocalStorageByKey<T>(key: string): T | null {
    const itemFromStorage = localStorage.getItem(key);

    return itemFromStorage ? (JSON.parse(itemFromStorage) as T) : null;
  }

  public static removeDataFromLocalStorage(key: string): void {
    localStorage.removeItem(key);
  }
}

export default LocalStorageService;
