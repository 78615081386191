import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';

import { TicketState } from '../../enums';

import { ITicketGetObject } from '../../interfaces/api/tickets';

export default class TicketRowModel {
  constructor(throughNumber: number, dto: ITicketGetObject) {
    makeAutoObservable(this, undefined, { autoBind: true });

    this.id = dto.id;
    this.number = dto.number;
    this.spectatorEmail = dto.spectatorEmail;
    this.spectatorPhone = dto.spectatorPhone;

    if (dto.activationTime) {
      this.activationTimeSource = dto.activationTime;
    }

    this.performanceName = dto.performance.name;
    this.state = dto.state;
  }

  public id = '';

  public number = '';

  public spectatorEmail = '';

  public spectatorPhone = '';

  public activationTimeSource = '';

  public performanceName = '';

  public state: TicketState | null = null;

  public get stateF() {
    if (this.state === TicketState.Refunded) {
      return 'Возвращён';
    }

    if (this.state === TicketState.Activated) {
      return 'Активирован';
    }

    if (this.state === TicketState.NonActivated) {
      return 'Не активирован';
    }

    return '';
  }

  public get isRefunded() {
    return this.state === TicketState.Refunded;
  }

  public setAsRefund() {
    if (this.state !== TicketState.Refunded) {
      this.state = TicketState.Refunded;
      return;
    }

    if (this.state === TicketState.Refunded) {
      this.state = TicketState.NonActivated;
    }
  }

  public get activationTime() {
    const timeZoneOffset = Math.abs(new Date(this.activationTimeSource)?.getTimezoneOffset());

    return this.activationTimeSource
      ? dayjs(this.activationTimeSource).add(timeZoneOffset, 'minute').format(`DD.MM.YYYY HH:mm:ss`)
      : '-';
  }
}
