import { useEffect } from 'react';
import { observer } from 'mobx-react';

import { TextL } from 'components/TextWrappers';

import { authUserStore } from 'stores';

import { Utils } from 'services/utils';

import c from './lastConfirmPage.module.scss';

const LastConfirmPage = () => {
  useEffect(() => {
    const userId = Utils.getUrlParam('userId');
    const token = Utils.getUrlParam('token');
    const password = Utils.getUrlParam('password');

    if (token && userId && !password) {
      authUserStore.registrationConfirm(userId, token);
    }

    if (token && userId && password) {
      authUserStore.passwordRecoveryConfirm(userId, token, password);
    }
  }, []);

  return (
    <div>
      <div className={c.messageWrapper}>
        {authUserStore.currentStatusText.map((item) => (
          <TextL text={item} key={item} />
        ))}
      </div>
    </div>
  );
};

export default observer(LastConfirmPage);
