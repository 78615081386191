import { observer } from 'mobx-react';

import { Add, Close } from '@mui/icons-material';
import SortIcon from '@mui/icons-material/Sort';

import { performanceStore } from 'stores';

import { FormRow, IconBtn, NumberInput, TextInput } from 'components';
import { TextM } from 'components/TextWrappers';

import c from './checkPoints.module.scss';

const CheckPoints = () => {
  return (
    <div className={c.checkPointsWrapper}>
      <div className={c.addBtnWrapper}>
        <IconBtn
          icon={<Add />}
          hint={'Добавить контрольную точку'}
          className={c.addBtn}
          onClick={performanceStore.performance.addCheckpoint}
        />

        <IconBtn
          icon={<SortIcon />}
          hint={'Сортировать по номеру'}
          className={c.sortBtn}
          onClick={performanceStore.performance.sortCheckPoints}
        />
      </div>

      {performanceStore.performance.checkpoints.map((item) => {
        const onRemove = () => {
          performanceStore.performance.removeCheckPoint(item.number);
        };

        return (
          <div className={c.checkRowPointWrapper}>
            <div className={c.removeBtnWrapper}>
              <IconBtn icon={<Close />} onClick={onRemove} className={c.removeBtn} />
            </div>

            <FormRow
              additionalClass={c.paramRow}
              label={'Название'}
              input={<TextInput value={item.name} onChange={item.onChangeName} />}
            />

            <FormRow
              additionalClass={c.paramRow}
              label={'Порядковый номер'}
              input={<NumberInput value={item.number} onChange={item.onChangeNumber} />}
            />

            <FormRow
              additionalClass={c.paramRow}
              label={'Адрес'}
              input={<TextInput value={item.address} onChange={item.onChangeAddress} />}
            />

            <FormRow
              additionalClass={c.paramRow}
              label={'Юнити-ассет сцены'}
              input={<TextInput value={item.addressablePrefabId} onChange={item.onChangeAddressablePrefabId} />}
            />

            <FormRow
              additionalClass={c.paramRow}
              label={'Юнити-ассет аудио-гида'}
              input={<TextInput value={item.guideAudioClipId} onChange={item.onChangeGuideAudioClipId} />}
            />

            <FormRow
              additionalClass={c.paramRow}
              label={'Поворот сцены'}
              input={<NumberInput value={item.headingAngle} onChange={item.onChangeHeadingAngle} />}
            />

            <div className={c.latLngWrapper}>
              <div className={c.posLabelWrapper}>
                <TextM text={'Долгота'} />
                <TextM text={'Широта'} />
              </div>

              <div className={c.inputsLngLat}>
                <FormRow
                  inputWidthInPercents={100}
                  additionalClass={c.paramRow}
                  label={''}
                  input={<NumberInput step={0.001} value={item.lat} onChange={item.onChangeLat} />}
                />

                <div style={{ width: '8px' }} />

                <FormRow
                  inputWidthInPercents={100}
                  additionalClass={c.paramRow}
                  label={''}
                  input={<NumberInput step={0.001} value={item.lng} onChange={item.onChangeLng} />}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(CheckPoints);
