import React from 'react';
import { observer } from 'mobx-react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { TextTbCell } from '../TextWrappers';

import Header from './Header/Header';

import { IVirtualTableColumn } from 'shared/interfaces/app/IVirtualTableColumn';

import c from './virtualTable.module.scss';

export interface ICoverageTable {
  startRowIndex: number | null;
  endRowIndex: number | null;
  startColumnIndex: number | null;
  endColumnsIndex: number | null;
}

interface IProps {
  idField?: string;
  columns: IVirtualTableColumn[];
  hasAnyAppliedFilters: boolean;
  data: any[];
  totalDataLength: number;
  lastUpdated?: string;
  tableRowAdditionalClass?: string;
  unselectableTextInCells?: boolean;
  coverageIndexes?: ICoverageTable;
  onRemoveAllFilters(): void;
  getNextPage(): void;
  onRowDoubleClick?(id: string): void;
  onRowClick?(id: string): void;

  onMouseDown?(startRowIndex: number, startColumnIndex: number, clientX: number, clientY: number): void;
  onMouseUp?(endRowIndex: number, endColumnIndex: number, clientX: number, clientY: number): void;
  onMouseOver?(endRowIndex: number, endColumnIndex: number): void;
}

const HEADER_ID = 'header_virtual_table';
const HEADER_BODY_ID = 'body_virtual_table';

const VirtualTable = (props: IProps) => {
  const {
    idField,
    columns,
    data,
    onRemoveAllFilters,
    hasAnyAppliedFilters,
    totalDataLength,
    tableRowAdditionalClass,
    lastUpdated,
    getNextPage,
    onRowDoubleClick,
    onRowClick,
  } = props;

  const tableRowClasses = tableRowAdditionalClass
    ? `${c.tableRow} ${tableRowAdditionalClass} ${onRowDoubleClick ? c.clickable : ''}`
    : `${c.tableRow}`;

  return (
    <div className={c.tableWrapper}>
      <Header
        id={HEADER_ID}
        hasAnyAppliedFilters={hasAnyAppliedFilters}
        columns={columns}
        onRemoveAllFilters={onRemoveAllFilters}
      />

      <div className={c.virtualTableWrapper} id={HEADER_BODY_ID}>
        <InfiniteScroll
          loader={null}
          scrollableTarget={HEADER_BODY_ID}
          dataLength={data.length}
          next={getNextPage}
          hasMore={data.length < totalDataLength}
          className={c.scrollableBody}>
          <>
            {data.map((row, rowIndex) => {
              const onDoubleClick = () => {
                if (onRowDoubleClick) {
                  onRowDoubleClick(row.id);
                }
              };

              const onClick = () => {
                if (onRowClick) {
                  onRowClick(row.id);
                }
              };

              return (
                <div
                  key={row[`${idField}`] || row.id}
                  className={lastUpdated === row.id ? `${tableRowClasses} ${c.lastUpdated}` : tableRowClasses}>
                  {columns.map((column, columnIndex) => {
                    return (
                      <div
                        onDoubleClick={onDoubleClick}
                        onClick={onClick}
                        className={c.tableCell}
                        key={columnIndex}
                        style={{
                          width: column.columnWidth,
                          minHeight: '42.8px',
                          minWidth: column.minWidth,
                        }}>
                        {column.renderCustomCell ? (
                          column.renderCustomCell(row)
                        ) : (
                          <TextTbCell color={(row as any).tableRowTextColor} text={(row as any)[column.dataField]} />
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default observer(VirtualTable);
