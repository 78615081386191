import { observer } from 'mobx-react';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { CheckBox, FormRow, IconBtn, NumberInput, Select, Spinner, TextInput } from 'components';
import { performanceStore } from 'stores';

import { TextS, TextXS } from 'components/TextWrappers';

import c from './map.module.scss';

const Info = () => {
  const onOpenLogoFileSelect = () => {
    const handler = document.getElementById('uploadLogoInput');
    if (handler) {
      handler.click();
    }
  };

  const onOpenMapImageFileSelect = () => {
    const handler = document.getElementById('uploadMapInput');
    if (handler) {
      handler.click();
    }
  };

  return (
    <div className={c.inputsWrapper}>
      <Spinner isLoading={performanceStore.isPending} />
      <FormRow
        additionalClass={c.commonParamRow}
        label={'Название'}
        required={true}
        input={
          <TextInput value={performanceStore.performance.name} onChange={performanceStore.performance.onChangeName} />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Сезон'}
        required={true}
        input={
          <Select
            items={performanceStore.performance.seasonsList}
            value={performanceStore.performance.selectedSeasonId}
            onChange={performanceStore.performance.selectSeason}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Логотип'}
        required={true}
        input={
          <div className={c.uploadFileInput}>
            <IconBtn icon={<UploadIcon />} onClick={onOpenLogoFileSelect} />
            <TextS text={'Загрузить логотип'} onClick={onOpenLogoFileSelect} />
            <TextXS className={c.notice} text={'215px x 215px'} onClick={onOpenLogoFileSelect} />
            <input
              accept="image/png"
              style={{ display: 'none' }}
              id={'uploadLogoInput'}
              type={'file'}
              onChange={performanceStore.performance.setLogoImage}
            />

            {performanceStore.performance.previewLogoImage && <CheckCircleIcon className={c.successUploaded} />}
          </div>
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Растровое изображение карты'}
        required={true}
        input={
          <div className={c.uploadFileInput}>
            <IconBtn icon={<UploadIcon />} onClick={onOpenMapImageFileSelect} />
            <TextS text={'Загрузить изображение карты'} onClick={onOpenMapImageFileSelect} />
            <TextXS className={c.notice} text={'327px x 491px'} onClick={onOpenLogoFileSelect} />
            <input
              accept="image/png"
              style={{ display: 'none' }}
              id={'uploadMapInput'}
              type={'file'}
              onChange={performanceStore.performance.setMapImage}
            />

            {performanceStore.performance.previewMapImage && <CheckCircleIcon className={c.successUploaded} />}
          </div>
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Автор'}
        required={true}
        input={
          <TextInput
            value={performanceStore.performance.author}
            onChange={performanceStore.performance.onChangeAuthor}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Сюжет'}
        required={true}
        input={
          <TextInput
            value={performanceStore.performance.description}
            onChange={performanceStore.performance.onChangeDescription}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Краткое описание'}
        required={true}
        input={
          <TextInput
            value={performanceStore.performance.shortDescription}
            onChange={performanceStore.performance.onChangeShortDescription}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Основано на произведении'}
        required={true}
        input={
          <TextInput
            value={performanceStore.performance.inspiredBy}
            onChange={performanceStore.performance.onChangeInspiredBy}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Продолжительность'}
        required={true}
        input={
          <NumberInput
            value={performanceStore.performance.duration}
            onChange={performanceStore.performance.onChangeDuration}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Протяжённость маршрута'}
        required={true}
        input={
          <NumberInput
            disabled={true}
            value={performanceStore.performance.distance}
            onChange={performanceStore.performance.onChangeDistance}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Ids событий (разделять `;`)'}
        required={true}
        input={
          <TextInput
            value={performanceStore.performance.eventIds}
            onChange={performanceStore.performance.onChangeEventIds}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Размер файла'}
        required={true}
        input={
          <NumberInput
            value={performanceStore.performance.arFileSize}
            onChange={performanceStore.performance.onChangeFileSize}
          />
        }
      />

      <FormRow
        additionalClass={c.commonParamRow}
        label={'Ограничение по возрасту'}
        required={true}
        input={
          <NumberInput
            value={performanceStore.performance.minAge}
            onChange={performanceStore.performance.onChangeMinAge}
          />
        }
      />

      <div className={c.soonAndArchiveControlsWrapper}>
        <FormRow
          additionalClass={c.commonParamRow}
          label={'Скоро'}
          input={
            <CheckBox
              checked={performanceStore.performance.isComingSoon}
              onChange={performanceStore.performance.onChangeIsComingSoon}
            />
          }
        />

        <FormRow
          additionalClass={c.commonParamRow}
          label={'Архивный'}
          input={
            <CheckBox
              checked={performanceStore.performance.isArchival}
              onChange={performanceStore.performance.onChangeIsArchive}
            />
          }
        />
      </div>
    </div>
  );
};

export default observer(Info);
