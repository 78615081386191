import React from 'react';
import { observer } from 'mobx-react';

import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Alert, CssBaseline, Snackbar } from '@mui/material';
import 'dayjs/locale/ru';
const ruLocale = ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

import Router from './modules/Router/Router';

import { appStore } from './stores';

import { TextS } from './components/TextWrappers';

import c from './app.module.scss';

const App = () => {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'} localeText={ruLocale}>
        <CssBaseline />

        <Router />
      </LocalizationProvider>

      <Snackbar
        open={appStore.snackbarState.isOpened}
        autoHideDuration={appStore.snackbarState.timeout}
        onClose={appStore.closeSnackbar}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
        <Alert onClose={appStore.closeSnackbar} severity={appStore.snackbarState.state} sx={{ width: '100%' }}>
          <TextS className={c.notice} text={appStore.snackbarState.message} />
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(App);
