import { makeAutoObservable } from 'mobx';

import { ISelectItem, ISnackbarState } from 'shared/interfaces/app';

import { SnackBarStates, TabNames } from 'shared/enums';
import { Utils } from 'services/utils';
import LocalStorageService from 'services/LocalStorageService';
import { Debounce } from '../shared/models/serve';
import { appStore } from './index';

const SNACKBAR_TIMEOUT = 3000;

const DEFAULT_SNACKBAR_STATE = {
  state: SnackBarStates.Success,
  message: '',
  timeout: SNACKBAR_TIMEOUT,
  isOpened: false,
};

const SELECTED_TAB = 'selected_tab';

class AppStore {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public tabs: ISelectItem[] = [
    { id: TabNames.Users, name: TabNames.Users },
    { id: TabNames.Shows, name: TabNames.Shows },
    { id: TabNames.Tickets, name: TabNames.Tickets },
    { id: TabNames.Info, name: TabNames.Info },
  ];

  public selectedTabId: TabNames | null = null;

  public snackbarState: ISnackbarState = DEFAULT_SNACKBAR_STATE;

  public init() {
    const selectedTab = Utils.getUrlParam(SELECTED_TAB);
    if (selectedTab) {
      this.onSelectTab(selectedTab as TabNames);
    }

    if (!appStore.selectedTabId) this.onSelectTab(TabNames.Users);

    LocalStorageService.removeDataFromLocalStorage(`filterParamsFor_${TabNames.Users}`);
    LocalStorageService.removeDataFromLocalStorage(`filterParamsFor_${TabNames.Shows}`);
    LocalStorageService.removeDataFromLocalStorage(`filterParamsFor_${TabNames.Tickets}`);
    LocalStorageService.removeDataFromLocalStorage(`filterParamsFor_${TabNames.Info}`);
  }

  public onSelectTab(tab: TabNames) {
    this.selectedTabId = tab;
    Utils.insertUrlParam(SELECTED_TAB, this.selectedTabId);
  }

  public closeSnackbar() {
    this.snackbarState = { ...this.snackbarState, isOpened: false };
  }

  public showSnackbar(type: SnackBarStates, message: string, timeout = SNACKBAR_TIMEOUT) {
    this.snackbarState = { state: type, message, timeout, isOpened: true };
  }

  public dropSnackBarToDefault() {
    this.snackbarState = DEFAULT_SNACKBAR_STATE;
  }

  public deInit() {
    this.snackbarState = { state: SnackBarStates.Success, message: '', timeout: SNACKBAR_TIMEOUT, isOpened: false };
  }
}

export default new AppStore();
