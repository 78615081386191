import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { TextM } from 'components/TextWrappers';

import { FirstConfirmPage, LastConfirmPage } from './comonents';

import { authUserStore } from 'stores';

import { appRoutes } from 'shared/constants/appRoutes';

import { Utils } from 'services/utils';

import { ConfirmPageMode } from 'shared/enums';

import c from './confirmPage.module.scss';

const ConfirmPage = () => {
  const history = useHistory();

  useEffect(() => {
    return () => authUserStore.dropAccessToConfirmPage();
  }, []);

  const goToAuth = () => {
    history.push(appRoutes.auth);
  };

  return (
    <div className={c.mainPageWrapper}>
      <div className={c.logoWrapper}></div>

      {Utils.getUrlParam('token') && <LastConfirmPage />}

      {authUserStore.confirmPageMode !== ConfirmPageMode.NotAvailable && <FirstConfirmPage />}

      <div className={c.redirectionAction}>
        <TextM text={'Перейти на страницу авторизации'} onClick={goToAuth} />
      </div>
    </div>
  );
};

export default observer(ConfirmPage);
