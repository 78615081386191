import { makeAutoObservable } from 'mobx';

import { ITicketsStoreDI } from '../../interfaces/app/di';

import { ITicketsParamsPostDto } from '../../interfaces/api/tickets/ITicketsParamsPostDto';
import { TicketState } from '../../enums';
import dayjs from 'dayjs';

export class TicketsFiltersControl {
  constructor(private ticketsStore: ITicketsStoreDI) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public spectatorEmail = '';

  public performanceId = '';

  public number = '';

  public spectatorPhone = '';

  public state: TicketState | null = TicketState.All;

  public hasActivationTime: boolean | null = null;

  public activationTimeFrom = '';

  public activationTimeTo = '';

  public init(initParams: ITicketsParamsPostDto) {
    this.spectatorEmail = initParams.spectatorEmail;
    this.performanceId = initParams.performanceId;
    this.number = initParams.number;
    this.spectatorPhone = initParams.spectatorPhone;
    this.state = initParams.state === null ? TicketState.All : initParams.state;
    this.activationTimeFrom = initParams.activationTimeFrom;
    this.activationTimeTo = initParams.activationTimeTo;
    this.hasActivationTime =
      initParams.state === TicketState.All || initParams.state === null
        ? null
        : initParams.state === TicketState.Refunded || initParams.state === TicketState.Activated;
  }

  public get hasAnyAppliedFilters() {
    return (
      !!this.spectatorEmail ||
      !!this.performanceId ||
      !!this.number ||
      !!this.spectatorPhone ||
      this.state !== TicketState.All ||
      !!this.hasActivationTime ||
      !!this.activationTimeFrom ||
      !!this.activationTimeTo
    );
  }

  public get hasTimedStatus() {
    return this.state === TicketState.Refunded || this.state === TicketState.Activated;
  }

  public onChangeSpectatorEmail(value: string) {
    this.spectatorEmail = value;
    this.ticketsStore.setStartRecordNumber(0);
  }

  public onChangePerformanceId(performanceId: string) {
    this.performanceId = performanceId;
    this.ticketsStore.setStartRecordNumber(0);
  }

  public onChangeNumber(value: string) {
    this.number = value;
    this.ticketsStore.setStartRecordNumber(0);
  }

  public onChangeSpectatorPhone(value: string) {
    this.spectatorPhone = value;
    this.ticketsStore.setStartRecordNumber(0);
  }

  public onChangeState(value: TicketState) {
    this.state = value;
    this.hasActivationTime =
      value === TicketState.All ? null : value === TicketState.Activated || value === TicketState.Refunded;
    this.ticketsStore.setStartRecordNumber(0);
  }

  public onChangeActivationTimeFrom(value: Date | null) {
    if (!value) {
      this.activationTimeFrom = '';
      return;
    }

    if (value) {
      const timeZoneOffset = Math.abs(value?.getTimezoneOffset());
      this.activationTimeFrom = dayjs(value).add(timeZoneOffset, 'minute').format('MM-DD-YYYY');
    }

    this.ticketsStore.setStartRecordNumber(0);
  }

  public onChangeActivationTimeTo(value: Date | null) {
    if (!value) {
      this.activationTimeTo = '';
      return;
    }

    if (value) {
      const timeZoneOffset = Math.abs(value?.getTimezoneOffset());
      this.activationTimeTo = dayjs(value).add(timeZoneOffset, 'minute').format('MM-DD-YYYY');
    }

    this.ticketsStore.setStartRecordNumber(0);
  }

  public dropDatesFilter() {
    this.activationTimeFrom = '';
    this.activationTimeTo = '';

    this.ticketsStore.setStartRecordNumber(0);
  }

  public deInit(preventRequest?: boolean) {
    this.activationTimeFrom = '';
    this.activationTimeTo = '';
    this.number = '';
    this.spectatorEmail = '';
    this.spectatorPhone = '';
    this.performanceId = '';
    this.state = TicketState.All;
    this.hasActivationTime = null;

    this.ticketsStore.setStartRecordNumber(0, preventRequest);
  }
}
