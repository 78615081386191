import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { TextL, TextM } from 'components/TextWrappers';

import { appRoutes } from 'shared/constants/appRoutes';

import c from './noAccessPage.module.scss';

const NoAccessPage = () => {
  const history = useHistory();

  const goToAuth = () => {
    history.push(appRoutes.auth);
  };

  return (
    <div className={c.mainPageWrapper}>
      <TextL text={'У вас нет доступа к этой странице'} />
      <div className={c.redirectionAction}>
        <TextM text={'Перейти на страницу авторизации'} onClick={goToAuth} />
      </div>
    </div>
  );
};

export default observer(NoAccessPage);
