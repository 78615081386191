import ITextProps from '../ITextProps';

import { FontWeight } from 'shared/enums';

import c from './textL.module.scss';

const TextL = ({ children, className, color, hexColor, fontWeight, text, textAlign, noWrap }: ITextProps) => {
  return (
    <div
      className={`${className || ''} ${c.h2Text}`}
      style={{
        textAlign: textAlign || 'initial',
        fontWeight: fontWeight || FontWeight.Light,
        color: hexColor ? hexColor : color ? `var(${color})` : '',
        whiteSpace: noWrap ? 'nowrap' : undefined,
      }}>
      {text || children}
    </div>
  );
};

export default TextL;
