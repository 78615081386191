import { TextS } from '../TextWrappers';

import { UserType } from 'shared/enums';
import { ISelectItem } from 'shared/interfaces/app';

import c from './userTypesSelector.module.scss';

interface IProps {
  onSelect(selected: UserType): void;
  selected: UserType;
}

const userTypes: ISelectItem[] = [
  { id: UserType.Admin.toString(), name: 'Администраторы' },
  { id: UserType.Spectator.toString(), name: 'Зрители' },
];

const UserTypesSelector = ({ onSelect, selected }: IProps) => {
  return (
    <div className={c.selectorWrapper}>
      {userTypes.map((item) => {
        const onClick = () => {
          const selected = item.id as any as UserType;
          onSelect(selected);
        };

        return (
          <div
            key={item.id}
            onClick={onClick}
            className={
              item.id === selected.toString()
                ? `${c.groupItem} ${c.selectedGroup} unselectable`
                : `${c.groupItem} unselectable`
            }>
            <TextS text={item.name} />
          </div>
        );
      })}
    </div>
  );
};

export default UserTypesSelector;
