import { makeAutoObservable } from 'mobx';

import LocalStorageService from 'services/LocalStorageService';
import ValidationModel, { Rule, ValidStatus } from '../serve/ValidationModel';

import { USER_INFO_STORAGE_KEY } from './UserInfoModel';
import { IUserAuthPostDto } from '../../interfaces/api/authUser';

class AuthUser {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  public validation = new ValidationModel([
    {
      rules: [{ rule: Rule.Requirement, description: 'Это обязательное поле' }],
      validStatus: ValidStatus.Unknown,
    },
    {
      rules: [{ rule: Rule.Requirement, description: 'Это обязательное поле' }],
      validStatus: ValidStatus.Unknown,
    },
  ]);

  public login = '';

  public password = '';

  public authSuccess: boolean | null = null;

  public isShowPassword = false;

  public setAuthSuccess(value: boolean | null) {
    this.authSuccess = value;
  }

  public onChangeLogin(login: string) {
    this.login = login;
    this.validation.checkForValid([this.login, this.password]);
  }

  public onChangePassword(password: string) {
    this.password = password;
    this.validation.checkForValid([this.login, this.password]);
  }

  public getPostDto(): IUserAuthPostDto | null {
    this.validation.activateValidation([this.login, this.password]);
    if (this.validation.modelIsNotValid) return null;

    return {
      email: this.login,
      password: this.password,
    };
  }

  public deInit() {
    this.login = '';
    this.password = '';
    this.isShowPassword = false;

    this.validation.deInit();
    return true;
  }

  public logout() {
    this.authSuccess = null;

    LocalStorageService.removeDataFromLocalStorage(USER_INFO_STORAGE_KEY);
  }
}

export default AuthUser;
