import dayjs from 'dayjs';

import { Utils } from 'services/utils';
import BaseApiService from './BaseApiService';

import { IPerformanceResponseDto, IPerformanceRowDto } from 'shared/interfaces/api/performacnes/IPerfomanceGetDto';
import { IPerformancePostDto } from '../shared/interfaces/api/performacnes/IPerformancePostDto';
import { ISeasonsResponseDto } from '../shared/interfaces/seasons/ISeasonsResponseDto';

class SeasonsApiService extends BaseApiService {
  constructor() {
    super('api/Season');
  }

  public getSeasons(): Promise<ISeasonsResponseDto> {
    const filterParams: any = {
      from: 0,
      count: 4,
    };

    return this.POST(`GetByFilter`, filterParams);
  }
}

export default new SeasonsApiService();
