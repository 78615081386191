import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { TabNames, TicketState } from 'shared/enums';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import BackHandIcon from '@mui/icons-material/BackHand';
import { DatePicker, IconBtn, Select, TabHeadLine, TextInput, VirtualTable } from 'components';

import { performanceStore, ticketsStore } from 'stores';

import { IVirtualTableColumn } from 'shared/interfaces/app';

import TicketRowModel from 'shared/models/tickets/TicketRowModel';
import { TextS } from 'components/TextWrappers';

import c from './tickets.module.scss';

const Tickets = () => {
  useEffect(() => {
    ticketsStore.debounce.deInitDebounce();
    ticketsStore.requestParamsControl.initFromURL(TabNames.Tickets);
    ticketsStore.getTickets();

    return () => ticketsStore.deInit(true);
  }, []);

  useEffect(() => {
    performanceStore.getPerformances();
  }, []);

  const columns: IVirtualTableColumn[] = [
    {
      dataField: 'number',
      columnWidth: '17%',
      name: 'Номер',
      filtered: !!ticketsStore.requestParamsControl.filtersControl.number,
      sortControl: ticketsStore.requestParamsControl.sortControl,
      onRemoveFilter: () => ticketsStore.requestParamsControl.filtersControl.onChangeNumber(''),
      filterControl: () => {
        return (
          <div className={c.columnFilterWrapper}>
            <TextInput
              value={ticketsStore.requestParamsControl.filtersControl.number}
              onChange={ticketsStore.requestParamsControl.filtersControl.onChangeNumber}
            />
          </div>
        );
      },
    },
    {
      dataField: 'spectatorPhone',
      columnWidth: '17%',
      name: 'Телефон зрителя',
      filtered: !!ticketsStore.requestParamsControl.filtersControl.spectatorPhone,
      sortControl: ticketsStore.requestParamsControl.sortControl,
      onRemoveFilter: () => ticketsStore.requestParamsControl.filtersControl.onChangeSpectatorPhone(''),
      filterControl: () => {
        return (
          <div className={c.columnFilterWrapper}>
            <TextInput
              value={ticketsStore.requestParamsControl.filtersControl.spectatorPhone}
              onChange={ticketsStore.requestParamsControl.filtersControl.onChangeSpectatorPhone}
            />
          </div>
        );
      },
    },
    {
      dataField: 'spectatorEmail',
      columnWidth: '17%',
      name: 'Email зрителя',
      filtered: !!ticketsStore.requestParamsControl.filtersControl.spectatorEmail,
      sortControl: ticketsStore.requestParamsControl.sortControl,
      onRemoveFilter: () => ticketsStore.requestParamsControl.filtersControl.onChangeSpectatorEmail(''),
      filterControl: () => {
        return (
          <div className={c.columnFilterWrapper}>
            <TextInput
              value={ticketsStore.requestParamsControl.filtersControl.spectatorEmail}
              onChange={ticketsStore.requestParamsControl.filtersControl.onChangeSpectatorEmail}
            />
          </div>
        );
      },
    },
    {
      dataField: 'performanceName',
      columnWidth: '17%',
      name: 'Название спектакля',
      filtered: !!ticketsStore.requestParamsControl.filtersControl.performanceId,
      sortControl: ticketsStore.requestParamsControl.sortControl,
      onRemoveFilter: () => ticketsStore.requestParamsControl.filtersControl.onChangePerformanceId(''),
      filterControl: () => {
        return (
          <div className={c.columnSelectFilterWrapper}>
            <Select
              items={performanceStore.selectList}
              value={ticketsStore.requestParamsControl.filtersControl.performanceId}
              onChange={ticketsStore.requestParamsControl.filtersControl.onChangePerformanceId}
            />
          </div>
        );
      },
    },
    {
      dataField: 'state',
      columnWidth: '17%',
      name: 'Статус',
      filtered: ticketsStore.requestParamsControl.filtersControl.state !== TicketState.All,
      sortControl: ticketsStore.requestParamsControl.sortControl,
      onRemoveFilter: () => ticketsStore.requestParamsControl.filtersControl.onChangeState(TicketState.All),
      filterControl: () => {
        return (
          <div className={c.columnSelectFilterWrapper}>
            <Select
              items={[
                { id: TicketState.All, name: 'Все' },
                { id: TicketState.Activated, name: 'Активирован' },
                { id: TicketState.NonActivated, name: 'Не активирован' },
                { id: TicketState.Refunded, name: 'Возвращён' },
              ]}
              onChange={ticketsStore.requestParamsControl.filtersControl.onChangeState}
              value={ticketsStore.requestParamsControl.filtersControl.state}
            />
          </div>
        );
      },
      renderCustomCell: (row: TicketRowModel) => {
        const onRefund = () => {
          ticketsStore.doRefund(row.id);
        };

        return (
          <div className={c.checkBoxWrapper}>
            <IconBtn
              disabled={row.state === TicketState.Activated}
              className={c.btn}
              hint={row.isRefunded ? 'Вернуть обычный статус' : 'Отметить как возвращённый'}
              icon={row.isRefunded ? <BackHandIcon /> : <ReplyAllIcon />}
              onClick={onRefund}
            />

            <TextS text={row.stateF} />
          </div>
        );
      },
    },
    {
      dataField: 'activationTime',
      columnWidth: '17%',
      name: 'Дата и время активации',
      filtered:
        !!ticketsStore.requestParamsControl.filtersControl.activationTimeFrom ||
        !!ticketsStore.requestParamsControl.filtersControl.activationTimeTo,
      sortControl: ticketsStore.requestParamsControl.sortControl,
      onRemoveFilter: () => ticketsStore.requestParamsControl.filtersControl.dropDatesFilter(),
      filterControl: () => {
        return (
          <div className={c.columnDateFilterWrapper}>
            <DatePicker
              disabled={!ticketsStore.requestParamsControl.filtersControl.hasTimedStatus}
              date={ticketsStore.requestParamsControl.filtersControl.activationTimeFrom}
              onChangeDate={ticketsStore.requestParamsControl.filtersControl.onChangeActivationTimeFrom}
            />
            <DatePicker
              disabled={!ticketsStore.requestParamsControl.filtersControl.hasTimedStatus}
              date={ticketsStore.requestParamsControl.filtersControl.activationTimeTo}
              onChangeDate={ticketsStore.requestParamsControl.filtersControl.onChangeActivationTimeTo}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={c.ticketsWrapper}>
      <TabHeadLine name={TabNames.Tickets} isPending={ticketsStore.isPending} />

      <div className={c.contentWrapper}>
        <VirtualTable
          columns={columns}
          data={ticketsStore.tickets}
          getNextPage={ticketsStore.getNextPage}
          hasAnyAppliedFilters={ticketsStore.requestParamsControl.filtersControl.hasAnyAppliedFilters}
          totalDataLength={ticketsStore.totalDataLength}
          onRemoveAllFilters={ticketsStore.requestParamsControl.filtersControl.deInit}
        />
      </div>

      <div className={c.bottomSpace}></div>
    </div>
  );
};

export default observer(Tickets);
