import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { performancesApiService } from 'apiServices';

import { TabNames, UIElemSizes } from 'shared/enums';

import { FormRow, IconBtn, Modal, PrimaryButton, Spinner, TabHeadLine, TextInput } from 'components';
import { TextL, TextM, TextS } from 'components/TextWrappers';

import theatreStore from 'stores/theatreStore';

import c from './info.module.scss';

const Info = () => {
  useEffect(() => {
    theatreStore.getTheatreInfo();
  }, []);

  const onOpenFileSelect = () => {
    const handler = document.getElementById('uploadImageInput');
    if (handler) {
      handler.click();
    }
  };

  return (
    <div className={c.infoWrapper}>
      <TabHeadLine
        name={TabNames.Info}
        isPending={theatreStore.isPending}
        freeContent={
          <div className={c.addBtnWrapper}>
            <PrimaryButton caption={'Добавить или изменить информацию'} onClick={theatreStore.openForm} />
          </div>
        }
      />

      <Modal
        confirmCaption={'Сохранить'}
        cancelCaption={'Отмена'}
        onClose={theatreStore.closeForm}
        onConfirm={theatreStore.putTheatreInfo}
        isOpened={theatreStore.isShowForm}>
        <div className={c.infoForm}>
          <TextL className={c.formTitle} text={'Обновление информации'} />

          <Spinner isLoading={theatreStore.isPending} size={UIElemSizes.Lg} />
          <FormRow
            inputWidthInPercents={45}
            additionalClass={c.commonParamRow}
            label={'Изображение'}
            input={
              <div className={c.uploadFileInput}>
                <IconBtn icon={<UploadIcon />} onClick={onOpenFileSelect} />
                <TextS text={'Загрузить изображение. 335px x 160px'} onClick={onOpenFileSelect} />
                <input
                  accept="image/png"
                  style={{ display: 'none' }}
                  id={'uploadImageInput'}
                  type={'file'}
                  onChange={theatreStore.theatreInfoForm.setImage}
                />

                {theatreStore.theatreInfoForm.previewImage && <CheckCircleIcon className={c.successUploaded} />}
              </div>
            }
          />

          <div className={c.imagePreview}>
            <img src={theatreStore.theatreInfoForm.previewImage} />
          </div>

          <FormRow
            inputWidthInPercents={80}
            label={'История'}
            input={
              <TextInput
                value={theatreStore.theatreInfoForm.history}
                onChange={theatreStore.theatreInfoForm.onChangeHistory}
              />
            }
          />
          <FormRow
            inputWidthInPercents={80}
            label={'Миссия'}
            input={
              <TextInput
                value={theatreStore.theatreInfoForm.mission}
                onChange={theatreStore.theatreInfoForm.onChangeMission}
              />
            }
          />
        </div>
      </Modal>

      <div className={c.contentWrapper}>
        <Spinner isLoading={theatreStore.isPending} size={UIElemSizes.Lg} />
        <div className={c.mobilePreview}>
          <TextM className={c.title} text={'Мобильный ТЮЗ'} />

          <TextM className={c.subTitle} text={'Что это такое'} />

          <div className={c.imageWrapper}>
            <div className={c.imageBg} />
            <img
              className={c.image}
              src={`${performancesApiService.host}/images/${theatreStore.theatreInfo.imageId}`}
            />
          </div>
          <TextS className={c.infoText} text={theatreStore.theatreInfo.history} />

          <TextM className={c.subTitle} text={'Как это работает'} />
          <TextS className={c.infoText} text={theatreStore.theatreInfo.mission} />
        </div>
      </div>

      <div className={c.bottomSpace}></div>
    </div>
  );
};

export default observer(Info);
